<ng-container *ngIf="!!formGroup" [formGroup]="formGroup">
    <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>{{placeholder}}</mat-label>
        <mat-select [formControlName]="fcName">
            <mat-option *ngFor="let option of options" [value]="option[value]">{{option[dv]}}</mat-option>
        </mat-select>
        <button *ngIf="isAddNew" mat-icon-button matSuffix type="button" (click)="addNew($event)">
            <mat-icon>add</mat-icon>
        </button>
        <mat-error *ngIf="formGroup.get(fcName).hasError('required')">
            required
        </mat-error>
    </mat-form-field>
</ng-container>