import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DiBaseSearchComponent } from '../../base/di.base-search.component';
import { DiSearchComponent } from '../../components/di-search/di-search.component';

@Injectable({
  providedIn: 'root'
})
export class DiSearchService {

  private onPageChange: BehaviorSubject<DiSearchComponent> = new BehaviorSubject<DiSearchComponent>(null);
  public onPageChange$: Observable<DiSearchComponent> = this.onPageChange.asObservable();

  private onEdit: BehaviorSubject<DiSearchComponent> = new BehaviorSubject<DiSearchComponent>(null);
  public onEdit$: Observable<DiSearchComponent> = this.onEdit.asObservable();

  private onNew: BehaviorSubject<DiSearchComponent> = new BehaviorSubject<DiSearchComponent>(null);
  public onNew$: Observable<DiSearchComponent> = this.onNew.asObservable();

  constructor() { }

  pageChange(searchComponent: DiSearchComponent) {
    let service = this;
    service.onPageChange.next(searchComponent);
  }

  new(searchComponent: DiSearchComponent) {
    let service = this;
    service.onNew.next(searchComponent);
  }

  edit(searchComponent: DiSearchComponent) {
    let service = this;
    service.onEdit.next(searchComponent);
  }
}
