<post-login-header></post-login-header>
<sidebar></sidebar>
<section class="content">
    <div class="container-fluid">
      <div class="row clearfix">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <router-outlet></router-outlet>
        </div>
    </div>
    </div>
</section>


