<di-form [formGroup]="uomForm" title="Uom">
  <div class="row">
    <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
      <di-textbox placeholder="Uom Name" fcName="name"></di-textbox>
    </div>
    <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
      <di-textbox placeholder="Display Value" fcName="dv"></di-textbox>
    </div>
  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
    <di-select placeholder="Type" fcName="type" [options]="types" value="id" dv="value" [isAddNew]="true" (onAddNew)="addNewType($event)"></di-select>
  </div>
  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
    <di-select placeholder="Status" fcName="status" [options]="satusOptions" value="value" dv="name"></di-select>
  </div>
  </div>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
      <di-textarea placeholder="Notes" fcName="notes"></di-textarea>
    </div>
  </div>
</di-form>