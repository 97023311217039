export abstract class DiStringUtil{

    private constructor() { }
    public static join(strs: Array<string>, delimiter: string):string{
        let returnStr: string = "";
        for(let str of strs){
            if(DiStringUtil.isNotBlank(str)){
                returnStr += (str+delimiter);
            }
        }
        returnStr = returnStr.substring(0, returnStr.length-delimiter.length);
        return returnStr;
    }
    public static isBlank(str: string):boolean{
        if(!str || null==str || undefined==str || ''==str){
            return true;
        }
        return false;
    }
    public static isNotBlank(str: string):boolean{
        return !DiStringUtil.isBlank(str);
    }
    public static isContains(fullString: string, subString: string):boolean{
        if(DiStringUtil.isBlank(fullString) || DiStringUtil.isBlank(subString)){
            return false;
        }
        return  fullString.includes(subString);
    }

    public static toTitle(str: string):string{
        if(DiStringUtil.isBlank(str)){
            return str;
        }
        return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
        
    }

    public static toTitleOnlyFirstChar(str: string):string{
        if(DiStringUtil.isBlank(str)){
            return str;
        }
        return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1);});
        
    }

    public static toLower(str: string):string{
        if(DiStringUtil.isBlank(str)){
            return str;
        }
        return str.toLowerCase();
        
    }

    public static format(str: string,  ...values):string{
        if(DiStringUtil.isBlank(str) || values.length<=0){
            return str;
        }
        values.forEach((value, idx) =>{
            str = str.replace(`{${idx}}`, value);
        });
        return str;
        
    }

    public static subtract(str: string, subStr: string):string{
        if(DiStringUtil.isBlank(str) || DiStringUtil.isBlank(subStr)){
            return str;
        }
        return str.replace(subStr,'');
        
    }

}