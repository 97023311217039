<section class="content">
  <div class="container-fluid">
    <div class="row clearfix">
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <div class="card">
          <div class="header">
            <h2>Reactive Forms (Outline)</h2>
          </div>
          <div class="body">
            <form class="register-form m-4" [formGroup]="register" (ngSubmit)="onRegister()">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>First name</mat-label>
                    <input matInput formControlName="first" required>
                    <mat-icon matSuffix>face</mat-icon>
                    <mat-error *ngIf="register.get('first').hasError('required')">
                      First name is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Last name</mat-label>
                    <input matInput formControlName="last">
                    <mat-icon matSuffix>face</mat-icon>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" required>
                    <mat-icon matSuffix (click)="hide = !hide">
                      {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="register.get('password').hasError('required')">
                      Password is required
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" required>
                    <mat-icon matSuffix>email</mat-icon>
                    <mat-error *ngIf="register.get('email').hasError('required') || register.get('email').touched">
                      Please enter a valid email address
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Address</mat-label>
                    <textarea matInput formControlName="address"></textarea>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>City</mat-label>
                    <input matInput formControlName="city" required>
                    <mat-icon matSuffix>location_city</mat-icon>
                    <mat-error *ngIf="register.get('city').hasError('required')">
                      City name is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>State</mat-label>
                    <input matInput formControlName="state" required>
                    <mat-icon matSuffix>business</mat-icon>
                    <mat-error *ngIf="register.get('state').hasError('required')">
                      State name is required
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="country" required>
                      <mat-option [value]="'India'">
                        India
                      </mat-option>
                      <mat-option [value]="'Algeria'">
                        Algeria
                      </mat-option>
                      <mat-option [value]="'Brazil'">
                        Brazil
                      </mat-option>
                      <mat-option [value]="'Poland'">
                        Poland
                      </mat-option>
                      <mat-option [value]="'Sri Lanka'">
                        Sri Lanka
                      </mat-option>
                      <mat-option [value]="'Thailand'">
                        Thailand
                      </mat-option>
                      <mat-option [value]="'Japan'">
                        Japan
                      </mat-option>
                    </mat-select>
                    <mat-icon matSuffix>flag</mat-icon>
                    <mat-error *ngIf="register.get('country').hasError('required')">
                      Country is required
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                  <mat-checkbox class="example-margin" formControlName="termcondition">I accept
                    terms
                    and conditions
                  </mat-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                  <button class="msr-3" [disabled]="!register.valid" mat-raised-button color="primary">Submit</button>
                  <button type="button" mat-button>Cancel</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <div class="card">
          <div class="header">
            <h2>Reactive Forms (Fill)</h2>
          </div>
          <div class="body">
            <form class="m-4" [formGroup]="secondForm" (ngSubmit)="onsecondFormSubmit()">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>First name</mat-label>
                    <input matInput formControlName="first" required>
                    <mat-icon matSuffix>face</mat-icon>
                    <mat-error *ngIf="secondForm.get('first').hasError('required')">
                      First name is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Last name</mat-label>
                    <input matInput formControlName="last">
                    <mat-icon matSuffix>face</mat-icon>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Password</mat-label>
                    <input matInput formControlName="password" [type]="hide2 ? 'password' : 'text'" required>
                    <mat-icon matSuffix (click)="hide2 = !hide2">
                      {{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="secondForm.get('password').hasError('required')">
                      Password is required
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" required>
                    <mat-icon matSuffix>email</mat-icon>
                    <mat-error *ngIf="secondForm.get('email').hasError('email') && secondForm.get('email').touched">
                      Please enter a valid email address
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Address</mat-label>
                    <textarea matInput formControlName="address"></textarea>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>City</mat-label>
                    <input matInput formControlName="city" required>
                    <mat-icon matSuffix>location_city</mat-icon>
                    <mat-error *ngIf="secondForm.get('city').hasError('required')">
                      City name is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>State</mat-label>
                    <input matInput formControlName="state" required>
                    <mat-icon matSuffix>business</mat-icon>
                    <mat-error *ngIf="secondForm.get('state').hasError('required')">
                      State name is required
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="country" required>
                      <mat-option [value]="'India'">
                        India
                      </mat-option>
                      <mat-option [value]="'Algeria'">
                        Algeria
                      </mat-option>
                      <mat-option [value]="'Brazil'">
                        Brazil
                      </mat-option>
                      <mat-option [value]="'Poland'">
                        Poland
                      </mat-option>
                      <mat-option [value]="'Sri Lanka'">
                        Sri Lanka
                      </mat-option>
                      <mat-option [value]="'Thailand'">
                        Thailand
                      </mat-option>
                      <mat-option [value]="'Japan'">
                        Japan
                      </mat-option>
                    </mat-select>
                    <mat-icon matSuffix>flag</mat-icon>
                    <mat-error *ngIf="secondForm.get('country').hasError('required')">
                      Country is required
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                  <mat-checkbox class="example-margin" formControlName="termcondition">I accept
                    terms
                    and conditions
                  </mat-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                  <button class="msr-3" [disabled]="!secondForm.valid" mat-raised-button color="primary">Submit</button>
                  <button type="button" mat-button>Cancel</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row clearfix">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="header">
            <h2>Reactive Forms (Standard)</h2>
          </div>
          <div class="body">
            <form class="m-4" [formGroup]="thirdForm" (ngSubmit)="onThirdFormSubmit()">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                  <mat-form-field class="example-full-width">
                    <mat-label>First name</mat-label>
                    <input matInput formControlName="first" required>
                    <mat-icon matSuffix>face</mat-icon>
                    <mat-error *ngIf="thirdForm.get('first').hasError('required')">
                      First name is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                  <mat-form-field class="example-full-width">
                    <mat-label>Last name</mat-label>
                    <input matInput formControlName="last">
                    <mat-icon matSuffix>face</mat-icon>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                  <mat-form-field class="example-full-width">
                    <mat-label>Password</mat-label>
                    <input matInput formControlName="password" [type]="hide3 ? 'password' : 'text'" required>
                    <mat-icon matSuffix (click)="hide3 = !hide3">
                      {{hide3 ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="thirdForm.get('password').hasError('required')">
                      Password is required
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                  <mat-form-field class="example-full-width">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" required>
                    <mat-icon matSuffix>email</mat-icon>
                    <mat-error *ngIf="thirdForm.get('email').hasError('email') && thirdForm.get('email').touched">
                      Please enter a valid email address
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                  <mat-form-field class="example-full-width">
                    <mat-label>Address</mat-label>
                    <textarea matInput formControlName="address"></textarea>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                  <mat-form-field class="example-full-width">
                    <mat-label>City</mat-label>
                    <input matInput formControlName="city" required>
                    <mat-icon matSuffix>location_city</mat-icon>
                    <mat-error *ngIf="thirdForm.get('city').hasError('required')">
                      City name is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                  <mat-form-field class="example-full-width">
                    <mat-label>State</mat-label>
                    <input matInput formControlName="state" required>
                    <mat-icon matSuffix>business</mat-icon>
                    <mat-error *ngIf="thirdForm.get('state').hasError('required')">
                      State name is required
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                  <mat-form-field class="example-full-width">
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="country" required>
                      <mat-option [value]="'India'">
                        India
                      </mat-option>
                      <mat-option [value]="'Algeria'">
                        Algeria
                      </mat-option>
                      <mat-option [value]="'Brazil'">
                        Brazil
                      </mat-option>
                      <mat-option [value]="'Poland'">
                        Poland
                      </mat-option>
                      <mat-option [value]="'Sri Lanka'">
                        Sri Lanka
                      </mat-option>
                      <mat-option [value]="'Thailand'">
                        Thailand
                      </mat-option>
                      <mat-option [value]="'Japan'">
                        Japan
                      </mat-option>
                    </mat-select>
                    <mat-icon matSuffix>flag</mat-icon>
                    <mat-error *ngIf="thirdForm.get('country').hasError('required')">
                      Country is required
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                  <mat-checkbox class="example-margin" formControlName="termcondition">I accept
                    terms
                    and conditions
                  </mat-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                  <button class="msr-3" [disabled]="!thirdForm.valid" mat-raised-button color="primary">Submit</button>
                  <button type="button" mat-button>Cancel</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
