import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit {
  isLinear = false;
  HFormGroup1: UntypedFormGroup;
  HFormGroup2: UntypedFormGroup;
  VFormGroup1: UntypedFormGroup;
  VFormGroup2: UntypedFormGroup;
  constructor(private _formBuilder: UntypedFormBuilder) {}
  ngOnInit() {
    this.HFormGroup1 = this._formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required]
    });
    this.HFormGroup2 = this._formBuilder.group({
      address: ['', Validators.required]
    });

    this.VFormGroup1 = this._formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required]
    });
    this.VFormGroup2 = this._formBuilder.group({
      address: ['', Validators.required]
    });
  }
}
