import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DiUrlService {
  private prevUrl: string = null;
  private currUrl: string = null;
  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();

  constructor() { }

  setCurrentUrl(currentUrl: string) {
    let service = this;
    service.prevUrl = service.currUrl;
    service.currUrl = currentUrl;
    service.previousUrl.next(service.prevUrl);
  }
}
