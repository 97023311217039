<section class="content">
    <div class="container-fluid">
      <!-- Widgets -->
      <div class="row">
        <div class="col-lg-3 col-sm-6">
          <div class="info-box7 l-bg-card2 order-info-box7">
            <div class="info-box7-block">
              <h4 class="m-b-20">Orders Received</h4>
              <h2 class="text-end"><i class="fas fa-cart-plus float-start"></i><span>358</span></h2>
              <p class="m-b-0">18% Higher Then Last Month</p>
            </div>
          </div>
        </div>
  
        <div class="col-lg-3 col-sm-6">
          <div class="info-box7 l-bg-purple order-info-box7">
            <div class="info-box7-block">
              <h4 class="m-b-20">Delivered</h4>
              <h2 class="text-end"><i class="fas fa-business-time float-start"></i><span>865</span></h2>
              <p class="m-b-0">21% Higher Then Last Month</p>
            </div>
          </div>
        </div>
  
        <div class="col-lg-3 col-sm-6">
          <div class="info-box7 l-bg-orange order-info-box7">
            <div class="info-box7-block">
              <h4 class="m-b-20">New Orders</h4>
              <h2 class="text-end"><i class="fas fa-chart-line float-start"></i><span>128</span></h2>
              <p class="m-b-0">37% Higher Then Last Month</p>
            </div>
          </div>
        </div>
  
        <div class="col-lg-3 col-sm-6">
          <div class="info-box7 l-bg-card1 order-info-box7">
            <div class="info-box7-block">
              <h4 class="m-b-20">Total Earning</h4>
              <h2 class="text-end"><i class="fas fa-dollar-sign float-start"></i><span>$25698</span></h2>
              <p class="m-b-0">10% Higher Then Last Month</p>
            </div>
          </div>
        </div>
      </div>
      <!-- #END# Widgets -->
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div class="card">
            <div class="header">
              <h2>
                <strong>Chart</strong> Sample</h2>
            </div>
            <div class="body">
              <canvas baseChart [chartType]="'line'" [datasets]="areaChartData" [labels]="areaChartLabels"
                [options]="areaChartOptions" [legend]="true">
              </canvas>
              <div class="row">
                <div class="col-4">
                  <p class="text-muted font-15 text-truncate">Target</p>
                  <h5>
                    <i class="fas fa-arrow-circle-up col-green m-r-5"></i>$15.3k
                  </h5>
                </div>
                <div class="col-4">
                  <p class="text-muted font-15 text-truncate">Last
                    week</p>
                  <h5>
                    <i class="fas fa-arrow-circle-down col-red m-r-5"></i>$2.8k
                  </h5>
                </div>
                <div class="col-4">
                  <p class="text-muted text-truncate">Last
                    Month</p>
                  <h5>
                    <i class="fas fa-arrow-circle-up col-green m-r-5"></i>$12.5k
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div class="card">
            <div class="header">
              <h2>
                <strong>Chart</strong> Sample</h2>
            </div>
            <div class="body">
              <canvas baseChart class="chart" [datasets]="barChartData" [labels]="barChartLabels"
                [options]="barChartOptions" [colors]="barChartColors" [legend]="barChartLegend"
                [chartType]="barChartType"></canvas>
              <div class="row">
                <div class="col-4">
                  <p class="text-muted font-15 text-truncate">Target</p>
                  <h5>
                    <i class="fas fa-arrow-circle-down col-red m-r-5"></i>$15.3k
                  </h5>
                </div>
                <div class="col-4">
                  <p class="text-muted font-15 text-truncate">Last
                    week</p>
                  <h5>
                    <i class="fas fa-arrow-circle-up col-green m-r-5"></i>$2.8k
                  </h5>
                </div>
                <div class="col-4">
                  <p class="text-muted text-truncate">Last
                    Month</p>
                  <h5>
                    <i class="fas fa-arrow-circle-down col-red m-r-5"></i>$12.5k
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
          <div class="card">
            <div class="header">
              <h2>
                <strong>Chart</strong> Data</h2>
            </div>
            <div class="body">
              <div id="chart">
                <div id="chart">
                  <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis"
                    [stroke]="chartOptions.stroke" [colors]="chartOptions.colors" [dataLabels]="chartOptions.dataLabels"
                    [legend]="chartOptions.legend" [markers]="chartOptions.markers" [grid]="chartOptions.grid"
                    [tooltip]="chartOptions.tooltip" [yaxis]="chartOptions.yaxis" [title]="chartOptions.title">
                  </apx-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card">
            <div class="header">
              <h2>
                <strong>Chart</strong> Data</h2>
            </div>
            <div class="body">
              <div id="chart">
                <div id="chart">
                  <apx-chart [series]="chartOptions2.series" [chart]="chartOptions2.chart"
                    [tooltip]="chartOptions2.tooltip" [dataLabels]="chartOptions2.dataLabels"
                    [plotOptions]="chartOptions2.plotOptions"></apx-chart>
                </div>
  
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <!-- <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card">
            <div class="header">
              <h2>
                <strong>New </strong>Projects</h2>
            </div>
            <div class="body">
              <ng-scrollbar style="height: 500px" visibility="hover">
                <div id="new-orders" class="media-list position-relative">
                  <div class="table-responsive">
                    <table id="new-orders-table" class="table table-hover table-xl mb-0">
                      <thead>
                        <tr>
                          <th class="border-top-0">Product</th>
                          <th class="border-top-0">Employees</th>
                          <th class="border-top-0">Cost</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-truncate">iPhone X</td>
                          <td class="text-truncate">
                            <ul class="list-unstyled order-list">
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user1.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user3.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <span class="badge">+2</span>
                              </li>
                            </ul>
                          </td>
                          <td class="text-truncate">$8999</td>
                        </tr>
                        <tr>
                          <td class="text-truncate">Pixel 2</td>
                          <td class="text-truncate">
                            <ul class="list-unstyled order-list">
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user4.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user5.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user6.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <span class="badge">+4</span>
                              </li>
                            </ul>
                          </td>
                          <td class="text-truncate">$5550</td>
                        </tr>
                        <tr>
                          <td class="text-truncate">OnePlus</td>
                          <td class="text-truncate">
                            <ul class="list-unstyled order-list">
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user7.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user8.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user9.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <span class="badge">+3</span>
                              </li>
                            </ul>
                          </td>
                          <td class="text-truncate">$9000</td>
                        </tr>
                        <tr>
                          <td class="text-truncate">Galaxy</td>
                          <td class="text-truncate">
                            <ul class="list-unstyled order-list">
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user1.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user2.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <span class="badge">+1</span>
                              </li>
                            </ul>
                          </td>
                          <td class="text-truncate">$7500</td>
                        </tr>
                        <tr>
                          <td class="text-truncate">Moto Z2</td>
                          <td class="text-truncate">
                            <ul class="list-unstyled order-list">
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user3.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user4.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user5.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <span class="badge">+4</span>
                              </li>
                            </ul>
                          </td>
                          <td class="text-truncate">$8500</td>
                        </tr>
                        <tr>
                          <td class="text-truncate">iPhone X</td>
                          <td class="text-truncate">
                            <ul class="list-unstyled order-list">
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user6.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user7.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <span class="badge">+2</span>
                              </li>
                            </ul>
                          </td>
                          <td class="text-truncate">$8999</td>
                        </tr>
                        <tr>
                          <td class="text-truncate">iPhone X</td>
                          <td class="text-truncate">
                            <ul class="list-unstyled order-list">
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user3.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user7.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user1.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <span class="badge">+4</span>
                              </li>
                            </ul>
                          </td>
                          <td class="text-truncate">$8999</td>
                        </tr>
                        <tr>
                          <td class="text-truncate">Pixel 2</td>
                          <td class="text-truncate">
                            <ul class="list-unstyled order-list">
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user4.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user6.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <span class="badge">+3</span>
                              </li>
                            </ul>
                          </td>
                          <td class="text-truncate">$5550</td>
                        </tr>
                        <tr>
                          <td class="text-truncate">OnePlus</td>
                          <td class="text-truncate">
                            <ul class="list-unstyled order-list">
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user7.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user8.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user9.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <span class="badge">+3</span>
                              </li>
                            </ul>
                          </td>
                          <td class="text-truncate">$9000</td>
                        </tr>
                        <tr>
                          <td class="text-truncate">Samsung</td>
                          <td class="text-truncate">
                            <ul class="list-unstyled order-list">
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user3.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user6.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user1.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <span class="badge">+2</span>
                              </li>
                            </ul>
                          </td>
                          <td class="text-truncate">$4563</td>
                        </tr>
                        <tr>
                          <td class="text-truncate">Nokia</td>
                          <td class="text-truncate">
                            <ul class="list-unstyled order-list">
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user9.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user2.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <img class="rounded-circle" src="assets/images/user/user5.jpg" alt="user">
                              </li>
                              <li class="avatar avatar-sm">
                                <span class="badge">+1</span>
                              </li>
                            </ul>
                          </td>
                          <td class="text-truncate">$8763</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </ng-scrollbar>
            </div>
          </div>
        </div> -->
        <!-- <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
          <div class="card">
            <div class="header">
              <h2>Order Details</h2>
            </div>
            <div class="tableBody">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Order Date</th>
                      <th>Status</th>
                      <th>Phone</th>
                      <th>Receipt</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="table-img">
                        <img src="assets/images/user/user8.jpg" alt="">
                      </td>
                      <td>John Deo</td>
                      <td>15-08-2019</td>
                      <td>
                        <div class="badge col-green">Paid</div>
                      </td>
                      <td>(123)123456</td>
                      <td>
                        <i class="far fa-file-pdf tbl-pdf"></i>
                      </td>
                      <td>
                        <button mat-icon-button class="tbl-action-btn">
                          <app-feather-icons [icon]="'edit'" [class]="'tbl-fav-edit'"></app-feather-icons>
                        </button>
                        <button mat-icon-button class="tbl-action-btn">
                          <app-feather-icons [icon]="'trash-2'" [class]="'tbl-fav-delete'"></app-feather-icons>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td class="table-img">
                        <img src="assets/images/user/user2.jpg" alt="">
                      </td>
                      <td>Jens Brincker</td>
                      <td>16-08-2019</td>
                      <td>
                        <div class="badge col-red">Unpaid</div>
                      </td>
                      <td>(123)123456</td>
                      <td>
                        <i class="far fa-file-pdf tbl-pdf"></i>
                      </td>
                      <td>
                        <button mat-icon-button class="tbl-action-btn">
                          <app-feather-icons [icon]="'edit'" [class]="'tbl-fav-edit'"></app-feather-icons>
                        </button>
                        <button mat-icon-button class="tbl-action-btn">
                          <app-feather-icons [icon]="'trash-2'" [class]="'tbl-fav-delete'"></app-feather-icons>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td class="table-img">
                        <img src="assets/images/user/user3.jpg" alt="">
                      </td>
                      <td>Mark Hay</td>
                      <td>18-08-2019</td>
                      <td>
                        <div class="badge col-green">Paid</div>
                      </td>
                      <td>(123)123456</td>
                      <td>
                        <i class="far fa-file-pdf tbl-pdf"></i>
                      </td>
                      <td>
                        <button mat-icon-button class="tbl-action-btn">
                          <app-feather-icons [icon]="'edit'" [class]="'tbl-fav-edit'"></app-feather-icons>
                        </button>
                        <button mat-icon-button class="tbl-action-btn">
                          <app-feather-icons [icon]="'trash-2'" [class]="'tbl-fav-delete'"></app-feather-icons>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td class="table-img">
                        <img src="assets/images/user/user4.jpg" alt="">
                      </td>
                      <td>Anthony Davie</td>
                      <td>17-08-2019</td>
                      <td>
                        <div class="badge col-red">Unpaid</div>
                      </td>
                      <td>(123)123456</td>
                      <td>
                        <i class="far fa-file-pdf tbl-pdf"></i>
                      </td>
                      <td>
                        <button mat-icon-button class="tbl-action-btn">
                          <app-feather-icons [icon]="'edit'" [class]="'tbl-fav-edit'"></app-feather-icons>
                        </button>
                        <button mat-icon-button class="tbl-action-btn">
                          <app-feather-icons [icon]="'trash-2'" [class]="'tbl-fav-delete'"></app-feather-icons>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td class="table-img">
                        <img src="assets/images/user/user6.jpg" alt="">
                      </td>
                      <td>Alan Gilchrist</td>
                      <td>23-08-2019</td>
                      <td>
                        <div class="badge col-green">Paid</div>
                      </td>
                      <td>(123)123456</td>
                      <td>
                        <i class="far fa-file-pdf tbl-pdf"></i>
                      </td>
                      <td>
                        <button mat-icon-button class="tbl-action-btn">
                          <app-feather-icons [icon]="'edit'" [class]="'tbl-fav-edit'"></app-feather-icons>
                        </button>
                        <button mat-icon-button class="tbl-action-btn">
                          <app-feather-icons [icon]="'trash-2'" [class]="'tbl-fav-delete'"></app-feather-icons>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td class="table-img">
                        <img src="assets/images/user/user7.jpg" alt="">
                      </td>
                      <td>Sue Woodger</td>
                      <td>26-08-2019</td>
                      <td>
                        <div class="badge col-orange">Pending</div>
                      </td>
                      <td>(123)123456</td>
                      <td>
                        <i class="far fa-file-pdf tbl-pdf"></i>
                      </td>
                      <td>
                        <button mat-icon-button class="tbl-action-btn">
                          <app-feather-icons [icon]="'edit'" [class]="'tbl-fav-edit'"></app-feather-icons>
                        </button>
                        <button mat-icon-button class="tbl-action-btn">
                          <app-feather-icons [icon]="'trash-2'" [class]="'tbl-fav-delete'"></app-feather-icons>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td class="table-img">
                        <img src="assets/images/user/user8.jpg" alt="">
                      </td>
                      <td>David Perry</td>
                      <td>29-08-2019</td>
                      <td>
                        <div class="badge col-red">Unpaid</div>
                      </td>
                      <td>(123)123456</td>
                      <td>
                        <i class="far fa-file-pdf tbl-pdf"></i>
                      </td>
                      <td>
                        <button mat-icon-button class="tbl-action-btn">
                          <app-feather-icons [icon]="'edit'" [class]="'tbl-fav-edit'"></app-feather-icons>
                        </button>
                        <button mat-icon-button class="tbl-action-btn">
                          <app-feather-icons [icon]="'trash-2'" [class]="'tbl-fav-delete'"></app-feather-icons>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td class="table-img">
                        <img src="assets/images/user/user9.jpg" alt="">
                      </td>
                      <td>Sneha Pandit</td>
                      <td>28-08-2019</td>
                      <td>
                        <div class="badge col-green">Paid</div>
                      </td>
                      <td>(123)123456</td>
                      <td>
                        <i class="far fa-file-pdf tbl-pdf"></i>
                      </td>
                      <td>
                        <button mat-icon-button class="tbl-action-btn">
                          <app-feather-icons [icon]="'edit'" [class]="'tbl-fav-edit'"></app-feather-icons>
                        </button>
                        <button mat-icon-button class="tbl-action-btn">
                          <app-feather-icons [icon]="'trash-2'" [class]="'tbl-fav-delete'"></app-feather-icons>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div> 
        </div>-->
      </div>
  
    </div>
  </section>
  