<div class="card">
    <div class="body">
      <div class="row">
        <div class="col-sm-12 di-serach">
          <div class="ngxTableHeader">
            <ul class="header-buttons-left ms-0 mb-0">
              <li class="dropdown">
                <h2>
                  <strong>{{title}}</strong></h2>
              </li>
              <li class="dropdown m-l-20">
                <label for="search-input"><i class="material-icons search-icon">search</i></label>
                <input [placeholder]="searchPlaceholder" type="text" class="browser-default search-field"
                  (keyup)='filterDatatable($event)' aria-label="Search box">
              </li>
            </ul>
            <ul class="header-buttons">
              <li>
                <button class="di-add" mat-stroked-button color="primary" (click)="new()">
                    <mat-icon class="col-white">add</mat-icon> Add
                </button>               
              </li>
            </ul>
          </div>
          <ngx-datatable #table class="material" 
            [columns]="columns"
            [rows]="pageDTO.records" 
            [columnMode]="ColumnMode.force" 
            [headerHeight]="50" 
            [footerHeight]="50" 
            [rowHeight]="30" 
            [externalPaging]="true"
            [count]="pageDTO.totalRecords"
            [offset]="pageDTO.pageNo"
            [limit]="pageDTO.pageSize"
            (page)="changePage($event)"
            >
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
  <ng-template #tmplSerialNo let-value="value" let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <span>
     {{(pageDTO.pageNo*pageDTO.pageSize)+rowIndex+1}}
    </span>
    <div class="di-row-action" *ngIf="tmplRowAction">
      <ng-template
                   [ngTemplateOutlet]="tmplRowAction"
                   [ngTemplateOutletContext]="{ $implicit: row, index: rowIndex }">
      </ng-template>
  </div>
  </ng-template>
  <ng-template #tmplRowAction let-row let-index="index">
    <button *ngIf="isEdit" mat-raised-button color="primary" (click)="edit(row.id)">
        <mat-icon>mode_edit</mat-icon>
    </button>
    <button *ngIf="isView" mat-raised-button color="primary">
        <mat-icon>visibility</mat-icon>
    </button>
    <!-- <button mat-raised-button color="warn">
        <mat-icon>delete</mat-icon>
    </button> -->
</ng-template>