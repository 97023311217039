import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { DiBaseSearchDTO } from '../../../di-core/base/di.base-search.dto';
import { DiPageDTO } from '../../../di-core/misc/di.page.dto';
import { DiResponseDTO } from '../../../di-core/misc/di.response.dto';

@Injectable({
  providedIn: 'root'
})
export class DummyDataService {

  constructor() { }  

  search(file: string, searchDTO: DiBaseSearchDTO): Observable<any> {
    return new Observable(observable=>{
      const req = new XMLHttpRequest();
      req.open('GET', `assets/data/med-erp/${file}.json`);
      req.onload = () => {
        let data: Array<any> = JSON.parse(req.response);
        data.forEach((record, index)=>{
          record['id'] = index;
        });
        data = this.filter(data, searchDTO);
        let records = data.slice((searchDTO.pageNo*searchDTO.pageSize), (searchDTO.pageNo+1)*searchDTO.pageSize);
        let pageDTO: DiPageDTO<any> = {
          pageNo: searchDTO.pageNo,
          pageSize: searchDTO.pageSize,
          records: records,
          totalRecords: data.length
        } as DiPageDTO<any>;
        
        observable.next({
          httpStatus: 200,
          body: pageDTO,
          message: null
        } as DiResponseDTO);
      };
      req.send();
    });
  }

  get(file: string, id: number): Observable<any> {
    return new Observable(observable=>{
      const req = new XMLHttpRequest();
      req.open('GET', `assets/data/med-erp/${file}.json`);
      req.onload = () => {
        const data: Array<any> = JSON.parse(req.response);
        let record = data[id]
       
        
        observable.next({
          httpStatus: 200,
          body: record,
          message: null
        } as DiResponseDTO);
      };
      req.send();
    });
  }

  getAllList(file: string, filter?: any): Observable<any> {
    return new Observable(observable=>{
      const req = new XMLHttpRequest();
      req.open('GET', `assets/data/med-erp/${file}.json`);
      req.onload = () => {
        let data: Array<any> = JSON.parse(req.response);
        data.forEach((record, index)=>{
          record['id'] = index;
        });
        if(!!filter){
          data = this.filter(data, filter);  
        }
              
        
        observable.next({
          httpStatus: 200,
          body: data,
          message: null
        } as DiResponseDTO);
      };
      req.send();
    });
  }
  private filter(data: Array<any>, search: any) {
    let keys = Object.keys(search);
    keys = keys.filter(key=>{
      if((['pageNo', 'pageSize', 'totalRecords'].indexOf(key)>-1)){
        return false;
      }
      else{
        return true;
      }
    })
    let hasSearch = false;
    for (let j = 0; j < keys.length; j++) {
      hasSearch = !!search[keys[j]];
    }
    if(!hasSearch){
      return data;
    }
    let filteredData = data.filter(function (record) {
      const props = Object.keys(record);
      for (let i = 0; i < props.length; i++) {
        for (let j = 0; j < keys.length; j++) {
          if(props[i]==keys[j]){
            if (
              record[props[i]]==search[keys[j]] ||
              !search[keys[j]]
            ) {
              return true;
            }
          }
        }
       
      }
    });
    return filteredData;
  }
}
