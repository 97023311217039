import { Component, Injector } from '@angular/core';
import { DiBaseSearchComponent } from 'src/app/di-core/base/di.base-search.component';
import { DiSearchColumn } from 'src/app/di-core/components/di-search/di-search.column';
import { ClientSearchDTO } from "../../../search-dto/common.client-search.dto";
import { ClientDTO } from "../../../dto/common.client.dto";

@Component({
  selector: 'client-search',
  templateUrl: './client-search.component.html',
  styleUrls: ['./client-search.component.scss']
})
export class ClientSearchComponent extends DiBaseSearchComponent<ClientSearchDTO, ClientDTO>{
  
  clientSearchDTO: ClientSearchDTO;


  constructor(protected injector: Injector) {
    super(injector);
  }

  diInit(){
    let component = this;
    component.columns = [
      new DiSearchColumn({name: "#", serialNo: true}),
      new DiSearchColumn({name: "Client Name", prop: "name", width: 150}),
      new DiSearchColumn({name: "Client code", prop: "code", width: 150}),
      new DiSearchColumn({name: "Notes", prop: "notes"}),
    ];
  }

}
