<di-form [formGroup]="itemForm" title="Item">
    <div class="row">
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Brand Code" fcName="brandCode"></di-textbox>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-autocomplete placeholder="Therapeutics" fcName="therapeutics" [options$]="therapeuticses$" (onLookup)="fetchTherapeuticses($event)" dv="value"></di-autocomplete>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-select placeholder="Form" fcName="iteamForm" [options]="itemForms" value="id" dv="value" [isAddNew]="true" (onAddNew)="addNewForm($event)"></di-select>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-select placeholder="Status" fcName="status" [options]="satusOptions" value="value" dv="name"></di-select>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Brand Name" fcName="brandName"></di-textbox>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Generic Name" fcName="genericName"></di-textbox>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-select placeholder="Specification" fcName="specification" [options]="specifications" value="id" dv="value" [isAddNew]="true" (onAddNew)="addNewSpecification($event)"></di-select>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Barcode" fcName="barcode"></di-textbox>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-select placeholder="Packing Style" fcName="packingStyle" [options]="packingStyles" value="id" dv="value" [isAddNew]="true" (onAddNew)="addNewPackingStyle($event)"></di-select>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Self Life" fcName="selfLife"></di-textbox>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="HSN" fcName="hsn"></di-textbox>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="GST" fcName="gst"></di-textbox>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="CESS" fcName="cess"></di-textbox>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-select placeholder="Tax Type" fcName="taxType" [options]="taxTypes" value="id" dv="value" [isAddNew]="true" (onAddNew)="addNewtaxType($event)"></di-select>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <h6>Shipper Size (LBH)</h6>
            <mat-divider></mat-divider>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Length" fcName="length"></di-textbox>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Breadth" fcName="breadth"></di-textbox>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Height" fcName="height"></di-textbox>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-select placeholder="Uom" fcName="lbhUOM" [options]="lbhUoms" value="id" dv="dv" [isAddNew]="true" (onAddNew)="addNewLbhUom($event)"></di-select>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Shipper Quantity" fcName="shipperQuantity"></di-textbox>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
            <di-file-upload placeholder="Registration Certificate" fcName="registrationCertificate"></di-file-upload>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-datepicker placeholder="Valid Till Date" fcName="registrationCertificateValidTillDate"></di-datepicker>
        </div>
    </div>
    <div class="row">
        <di-textarea placeholder="Composition" fcName="composition"></di-textarea>
    </div>
    <div class="row">
        <di-textarea placeholder="Other Specific Requirement" fcName="otherSpecificRequirement"></di-textarea>
    </div>
</di-form>