import { Component, OnInit, Input, Host } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DiFormService } from '../../../service/di-form-service/di-form.service';
import { DiFormComponent } from '../../di-form/di-form.component';

@Component({
  selector: 'di-textbox',
  templateUrl: './di-textbox.component.html',
  styleUrls: ['./di-textbox.component.sass']
})
export class DiTextboxComponent implements OnInit {
  @Input() placeholder: string;
  @Input() fcName: string;
  @Input() hintMessage: string;
  @Input() readonly: boolean = false;

  diForm: DiFormComponent
  formGroup: FormGroup;
  errorMessage: string;

  constructor(@Host() diForm: DiFormComponent, private formService: DiFormService) {
    let component = this;
    component.diForm = diForm;
  }
  ngOnInit(): void {
    let component = this;
    component.formService.initFormGroup$.subscribe(initFormGroup => {
      if (initFormGroup == component.diForm.formGroup) {
        component.formGroup = component.diForm.formGroup;
      }
    })
  }

}
