import { Component, ContentChild, Injector, Optional, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Params } from '@angular/router';
import { DummyDataService } from 'src/app/med-erp/service/dummy-data-service/dummy-data.service';
import { DiResponseDTO } from '../misc/di.response.dto';
import { DiBaseComponent } from './di.base.component';
import { DiBaseDTO } from './di.base.dto';
import { DiFormService } from "../index";

@Component({
    template: '',
})
export abstract class DiBaseFormComponent<T extends DiBaseDTO> extends DiBaseComponent {
    private _id: number;
    public isEdit = false;
    public isCreate = true;
    protected fb: FormBuilder;
    protected dtoName: string;
    protected formName: string;
    protected dummyDataService: DummyDataService;
    protected formService: DiFormService;
    @Output() public onSave = new EventEmitter();
    @Output() public onCancel = new EventEmitter();

    constructor(protected injector: Injector, @Optional() protected options?: Object) {
        super(injector, options);
        let component = this;
        component.fb = injector.get(FormBuilder);
        component.dummyDataService = injector.get(DummyDataService);
        component.formService = injector.get(DiFormService);
        component.dtoName = component.modulelName + "DTO";
        component.formName = component.modulelName + "Form";

        component[component.formName] = component.fb.group({});
        // component.route.params.subscribe((params: Params) => {
        //     if (!!params['id']) {
        //         component.id = params['id'];
        //     }
        //     else {
        //         component[component.dtoName] = component.getDTO();
        //         if (typeof component["initForm"] == "function") {
        //             component["initForm"]();
        //         }
        //         let formGroup: FormGroup = component[component.formName];
        //         component.formService.setInitFormGroup(formGroup);
        //     }
        // });
    }

    ngOnInit(): void {
        let component = this;
        component.route.params.subscribe((params: Params) => {
            if (!!params['id']) {
                component.id = params['id'];
            }
            else {
                component[component.dtoName] = component.getDTO();
                console.log("component.dummyDataService ", component.dummyDataService);
                if (typeof component["initForm"] == "function") {
                    component["initForm"]();
                }
                let formGroup: FormGroup = component[component.formName];
                component.formService.setInitFormGroup(formGroup);
                if (typeof component["diInit"] == "function") {
                    component["diInit"]();
                }
            }
        });
        component.formService.onSubmit$.subscribe(initFormGroup => {
            if (initFormGroup == component[component.formName]) {
                component.save();
            }
        });
        component.formService.onCancel$.subscribe(initFormGroup => {
            if (initFormGroup == component[component.formName]) {
                component.cancel();
            }
        });
      
    }

    get() {
        let component = this;
        component.dummyDataService.get(component.modulelName, component.id).subscribe((responseDTO: DiResponseDTO) => {
            component[component.dtoName] = responseDTO.body;
            if (typeof component["initForm"] == "function") {
                component["initForm"]();
            }
            let formGroup: FormGroup = component[component.formName];
            component.formService.setInitFormGroup(formGroup);
            if (typeof component["diInit"] == "function") {
                component["diInit"]();
            }
        });
    }

    protected getDTO<T extends DiBaseDTO>() {
        return {} as T;
    }

    public save() {
        let component = this;
        let form: FormGroup = component[component.formName];
        console.log('Form Value', form.value);
        if (form.valid) {
            if(component.isDialog){
                component.closeDialog(form.value);
            }
            else{
                component.goToSearch()
            }            
            component.onSave.emit(component[component.dtoName]);
        }

    }

    public cancel() {
        let component = this;
        if(component.isDialog){
            component.closeDialog();
        }
        else{
            component.goToSearch();
        }
        
        component.onCancel.emit(component[component.dtoName]);
    }

    private closeDialog(dto?: T){
        let component = this;
        component.dialogRef.close(dto);
    }
    goToSearch() {
        let component = this;

        component.router.navigateByUrl(component.baseModuleURL + "/search");
    }

    @Input() set id(id: number) {
        let component = this;
        component._id = id;
        if (!!component.id) {
            component.isEdit = true;
            component.isCreate = false;
            component.get();
        }
    }
    get id(): number {
        let component = this;
        return component._id;
    }

}
