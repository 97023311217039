<ng-container *ngIf="!!formGroup" [formGroup]="formGroup">
  <mat-form-field class="example-full-width" appearance="outline">
    <mat-label>{{placeholder}}</mat-label>
    <input type="text" matInput [formControlName]="fcName" [matAutocomplete]="auto" (keyup)="filterOptions($event)">
    <button *ngIf="addNew" mat-icon-button matSuffix type="button" (click)="fnAddNew($event)">
      <mat-icon>add</mat-icon>
    </button>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onOptionSelect($event)">
      <mat-option *ngFor="let option of filteredOptions" [value]="option" [innerHTML]="getOptionDv(option)">
        {{option.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-container>