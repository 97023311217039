import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ListMasterConfig } from "../list-master.config";

@Component({
  selector: 'list-master-search',
  templateUrl: './list-master-search.component.html',
  styleUrls: ['./list-master-search.component.scss']
})
export class ListMasterSearchComponent implements OnInit {
  private type: string;

  private options: Map<string, any> = new Map();
  config: any ={}

  @ViewChild('tmplListMasterSearchWidget', { read: TemplateRef, static: true })
  tmplListMasterSearchWidget: TemplateRef<any>;

  @ViewChild('vcListMasterSearchWidget', { read: ViewContainerRef, static: true })
  vcListMasterSearchWidget: ViewContainerRef;

  constructor(private route: ActivatedRoute) {
    let component = this;
    component.route.params.subscribe((params: Params) => {
      if (!!params['type']) {
        component.type = params['type'];
        component.config = ListMasterConfig.get(component.type);
        component.render();
      }
    });
  }
  ngOnInit(): void {
    let component = this;
    component.render();
  }

  render(){
    let component = this;
    if(!!component.vcListMasterSearchWidget){
      component.vcListMasterSearchWidget.clear();
      component.vcListMasterSearchWidget.createEmbeddedView(component.tmplListMasterSearchWidget);
    }
    
  }
}

