export class DiFileUploadUtil {

  private constructor() { }
  public static INVALID_IMAGE_ERROR_MSG: string = "Only jpg/jpeg and png files are allowed!";
  public static VALID_IMAGE_EXTENSION: string = "jpg,jpeg,png,JPG,JPEG,PNG";
  public static INVALID_EXCEL_ERROR_MSG: string = "Only xls and xlsx files are allowed!";
  public static VALID_EXCEL_EXTENSION: string = "xls,xlsx,XLS,XLSX";

  public static isValidImageFile(fileName: string, validFileExtension: string): boolean {
    let idxDot = fileName.lastIndexOf(".") + 1;
    let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    let allowedImageExtension: string[] = validFileExtension.split(",");
    if (allowedImageExtension.includes(extFile)) {
      return true;
    } else {
      return false;
    }
  }
}
