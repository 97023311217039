import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { DiResponseDTO } from 'src/app/di-core/misc/di.response.dto';
import { ListMasterFormWidgetComponent } from 'src/app/med-erp/common/list-master/list-master-form-widget/list-master-form-widget.component';
import { ListMasterConfig } from 'src/app/med-erp/common/list-master/list-master.config';
import { ListMasterDTO } from 'src/app/med-erp/dto/common.list-master.dto';
import { ItemDTO } from 'src/app/med-erp/dto/inventory.item.dto';
import { DummyDataService } from 'src/app/med-erp/service/dummy-data-service/dummy-data.service';
import { CustomerOrderDetailDTO } from "../../../";
import { ItemFormComponent } from '../../item/item-form/item-form.component';

@Component({
  selector: 'customer-order-detail',
  templateUrl: './customer-order-detail.component.html',
  styleUrls: ['./customer-order-detail.component.scss']
})
export class CustomerOrderDetailComponent implements OnInit {
  @Input() customerOrderDetailDTO: CustomerOrderDetailDTO;
  @Input() customerOrderForm: FormGroup;
  @Input() toggleDetail$: Observable<number>;
  @Input() index: number;
  @Input() orderDetailTypes: Array<any>;
  
  isExpanded: boolean =  false;
  incoterms: Array<ListMasterDTO> = [];
  shipmentTypes: Array<ListMasterDTO> = [];

  private items: BehaviorSubject<Array<ItemDTO>> = new BehaviorSubject<Array<ItemDTO>>(null);
  public items$: Observable<Array<ItemDTO>> = this.items.asObservable();

  constructor(private fb: FormBuilder, private dummyDataService: DummyDataService, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    let component = this;
    component.toggleDetail$.subscribe(index=>{
      if(component.index == index || index == -100){
        component.isExpanded = true;
      }
      else{
        component.isExpanded = false;
      }
    });
    component.fetchIncoterms();
    component.fetchShipmentTypes();

    component.customerOrderForm.addControl(`type${component.index}`, component.fb.control(component.customerOrderDetailDTO.type, [Validators.required,]));
    component.customerOrderForm.addControl(`itemId${component.index}`, component.fb.control(component.customerOrderDetailDTO.itemId, [Validators.required,]));
    component.customerOrderForm.addControl(`quantity${component.index}`, component.fb.control(component.customerOrderDetailDTO.quantity, [Validators.required,]));
    component.customerOrderForm.addControl(`rate${component.index}`, component.fb.control(component.customerOrderDetailDTO.rate, [Validators.required,]));
    component.customerOrderForm.addControl(`total${component.index}`, component.fb.control(component.customerOrderDetailDTO.total, [Validators.required,]));
    component.customerOrderForm.addControl(`itemGenericName${component.index}`, component.fb.control(component.customerOrderDetailDTO.itemGenericName));
    component.customerOrderForm.addControl(`itemTherapeutics${component.index}`, component.fb.control(component.customerOrderDetailDTO.itemTherapeutics));
    component.customerOrderForm.addControl(`iteamForm${component.index}`, component.fb.control(component.customerOrderDetailDTO.iteamForm));
    component.customerOrderForm.addControl(`itemPackingStyle${component.index}`, component.fb.control(component.customerOrderDetailDTO.itemPackingStyle));
    component.customerOrderForm.addControl(`incoterm${component.index}`, component.fb.control(component.customerOrderDetailDTO.incoterm, [Validators.required,]));
    component.customerOrderForm.addControl(`shipmentType${component.index}`, component.fb.control(component.customerOrderDetailDTO.shipmentType));
    component.customerOrderForm.addControl(`invoiceRate${component.index}`, component.fb.control(component.customerOrderDetailDTO.invoiceRate));
    component.customerOrderForm.addControl(`lastInvoiceRate${component.index}`, component.fb.control(component.customerOrderDetailDTO.lastInvoiceRate));
    component.customerOrderForm.addControl(`strength${component.index}`, component.fb.control(component.customerOrderDetailDTO.strength));
    component.customerOrderForm.addControl(`referenceProduct${component.index}`, component.fb.control(component.customerOrderDetailDTO.referenceProduct));
    component.customerOrderForm.addControl(`specialRequirement${component.index}`, component.fb.control(component.customerOrderDetailDTO.specialRequirement));
    component.customerOrderForm.addControl(`shipper${component.index}`, component.fb.control(component.customerOrderDetailDTO.shipper));
  }

  fetchItems(searchString?: string) {
    let component = this;
    component.dummyDataService.getAllList("item").subscribe((responseDTO: DiResponseDTO) => {
      let itemData = responseDTO.body;
      if (!!searchString) {
        itemData = itemData.filter(
          (option) => option.brandName.toLowerCase().indexOf(searchString) === 0
        );
      }
      component.items.next(itemData);
    })
  }
  fetchIncoterms() {
    let component = this;
    component.dummyDataService.getAllList("listMaster", { "code": ListMasterConfig.get('incoterm').code }).subscribe((responseDTO: DiResponseDTO) => {
      component.incoterms = responseDTO.body;
    })
  }
  addNewIncoterm(event) {
    let component = this;
    const dialogRef = component.dialog.open(ListMasterFormWidgetComponent);
    let listMasterFormWidgetComponent: ListMasterFormWidgetComponent = dialogRef.componentInstance;
    listMasterFormWidgetComponent.config = ListMasterConfig.get('incoterm');
    listMasterFormWidgetComponent.setDialogRef(dialogRef);
    dialogRef.afterClosed().subscribe(listMasterDTO => {
      if (!!listMasterDTO) {
        listMasterDTO.id = component.incoterms.length;
        component.incoterms.push(listMasterDTO);
        component.customerOrderForm.controls['incoterm'].setValue(listMasterDTO.id);
      }

    });
  }
  fetchShipmentTypes() {
    let component = this;
    component.dummyDataService.getAllList("listMaster", { "code": ListMasterConfig.get('shipment-type').code }).subscribe((responseDTO: DiResponseDTO) => {
      console.log("responseDTO.body ", responseDTO.body);
      component.shipmentTypes = responseDTO.body;
    })
  }
  addNewShipmentType(event) {
    let component = this;
    const dialogRef = component.dialog.open(ListMasterFormWidgetComponent);
    let listMasterFormWidgetComponent: ListMasterFormWidgetComponent = dialogRef.componentInstance;
    listMasterFormWidgetComponent.config = ListMasterConfig.get('shipment-type');
    listMasterFormWidgetComponent.setDialogRef(dialogRef);
    dialogRef.afterClosed().subscribe(listMasterDTO => {
      if (!!listMasterDTO) {
        listMasterDTO.id = component.shipmentTypes.length;
        component.shipmentTypes.push(listMasterDTO);
        component.customerOrderForm.controls['shipmentType'].setValue(listMasterDTO.id);
      }

    });
  }
  onFormClick(event){
    let component = this;
    event.stopPropagation();
  }

  onItemSelect(itemDTO: ItemDTO){
    let component = this;
    console.log("onItemSelect itemDTO ", itemDTO);
    component.customerOrderForm.controls[`itemGenericName${component.index}`].setValue(itemDTO.genericName);
    component.customerOrderForm.controls[`itemTherapeutics${component.index}`].setValue(itemDTO.therapeutics);
    component.customerOrderForm.controls[`iteamForm${component.index}`].setValue(itemDTO.iteamForm);
    component.customerOrderForm.controls[`itemPackingStyle${component.index}`].setValue(itemDTO.packingStyle);
    
  }

  addNewItem(event) {
    let component = this;
    const dialogRef = this.dialog.open(ItemFormComponent);
    let itemFormComponent: ItemFormComponent = dialogRef.componentInstance;
    itemFormComponent.setDialogRef(dialogRef);
    dialogRef.afterClosed().subscribe(itemDTO => {
      if (!!itemDTO) {
        // itemDTO.id = component.items.length;
        // component.items.push(itemDTO);
        component.customerOrderForm.controls[`itemId${component.index}`].setValue(itemDTO.brandName);
        component.onItemSelect(itemDTO);
      }

    });
  }

  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
}
