import { Component, Injector, Input } from '@angular/core';
import { DiBaseSearchComponent } from '../../../../di-core/base/di.base-search.component';
import { ListMasterSearchDTO } from "../../../search-dto/common.list-master-search.dto";
import { ListMasterDTO } from "../../../dto/common.list-master.dto";
import { DiSearchColumn } from "../../../../di-core/components/di-search/di-search.column";
import { DiStringUtil } from 'src/app/di-core/util/di.string.util';

@Component({
  selector: 'list-master-search-widget',
  templateUrl: './list-master-search-widget.component.html',
  styleUrls: ['./list-master-search-widget.component.scss']
})
export class ListMasterSearchWidgetComponent extends  DiBaseSearchComponent<ListMasterSearchDTO, ListMasterDTO>{
  listMasterSearchDTO: ListMasterSearchDTO;
  @Input() config: any ={};

  constructor(protected injector: Injector) {
    super(injector, {modulelName: "listMaster"});
    
  }
  diPreSearch(){
    let component = this;
    component.listMasterSearchDTO.code = component.config.code;
  }
  diInit(){
    let component = this;
    // component.baseModuleURL = 
    component.baseModuleURL = DiStringUtil.subtract(component.router.url, '/search');
    component.columns = [
      new DiSearchColumn({name: "#", serialNo: true}),
      new DiSearchColumn({name: component.config.valueName, prop: "value", width: 150}),
      new DiSearchColumn({name: "Notes", prop: "notes"})
    ];
  }
}
