import { Component, OnInit, Input, Host } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DiFormService } from '../../../service/di-form-service/di-form.service';
import { DiFormComponent } from '../../di-form/di-form.component';

@Component({
  selector: 'di-textarea',
  templateUrl: './di-textarea.component.html',
  styleUrls: ['./di-textarea.component.scss']
})
export class DiTextareaComponent implements OnInit {
  @Input() placeholder: string;
  @Input() fcName: string;
  @Input() hintMessage: string;

  diForm: DiFormComponent
  formGroup: FormGroup;
  errorMessage: string;

  constructor(@Host() diForm: DiFormComponent, private formService: DiFormService) {
    let component = this;
    component.diForm = diForm;
  }
  ngOnInit(): void {
    let component = this;
    component.formService.initFormGroup$.subscribe(initFormGroup => {
      if (initFormGroup == component.diForm.formGroup) {
        component.formGroup = component.diForm.formGroup;
      }
    })
  }
}
