<di-form [formGroup]="listMasterForm" [title]="config.title">
    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 mb-2">
        <di-textbox [placeholder]="config.valueName" fcName="value"></di-textbox>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
        <di-select placeholder="Status" fcName="status" [options]="satusOptions" value="value" dv="name"></di-select>
      </div>
    </div>
    <div class="row">
      <di-textarea placeholder="Notes" fcName="notes"></di-textarea>
    </div>
  </di-form>