import { Component, Injector, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { DiBaseFormComponent } from 'src/app/di-core/base/di.base-form.component';
import { DiStringUtil } from 'src/app/di-core/util/di.string.util';
import { ListMasterDTO } from "../../../dto/common.list-master.dto";

@Component({
  selector: 'list-master-form-widget',
  templateUrl: './list-master-form-widget.component.html',
  styleUrls: ['./list-master-form-widget.component.scss']
})
export class ListMasterFormWidgetComponent extends DiBaseFormComponent<ListMasterDTO>{

  @Input() config: any ={};
  
  listMasterDTO: ListMasterDTO;
  listMasterForm: FormGroup;

  satusOptions: Array<any> = [
    {name: "Active", value: "ACTIVE"},
    {name: "Inactive", value: "INACTIVE"}
  ]

  constructor(protected injector: Injector) {
    super(injector, {modulelName: "listMaster"});
  }
  diInit(){
    let component = this;
    if(component.isEdit){
      component.baseModuleURL = DiStringUtil.subtract(component.router.url, '/form/'+component.id);
    }
    else{
      component.baseModuleURL = DiStringUtil.subtract(component.router.url, '/form');
    }
  }
  
  initForm() {
    let component = this;
 
    component.listMasterForm.addControl('value', component.fb.control(component.listMasterDTO.value, [Validators.required, Validators.pattern('[a-zA-Z]+')]));
    component.listMasterForm.addControl('status', component.fb.control(component.listMasterDTO.status, [Validators.required,]));
    component.listMasterForm.addControl('notes', component.fb.control(component.listMasterDTO.notes)); 

  }
}
