<di-form [formGroup]="customerOrderForm" [isSubForm]="true">
<mat-expansion-panel [expanded]="isExpanded" (opened)="setStep(0)">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <div class="row" (click)="onFormClick($event)">
                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 mb-2">
                    <div class="row" (click)="onFormClick($event)">
                        <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 mb-2">
                            {{index+1}}
                        </div>
                        <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 mb-2">
                            <di-select placeholder="Type" fcName="type{{index}}" [options]="orderDetailTypes" value="value" dv="name"></di-select>
                        </div>
                    </div>
                   
                    
                </div>
                <div class="col-xl-auto col-lg-auto col-md-12 col-sm-12 mb-2">
                    <di-autocomplete placeholder="Brand Name" fcName="itemId{{index}}" [options$]="items$" (onLookup)="fetchItems($event)" dv="brandName" (onSelect)="onItemSelect($event)" (onAddNew)="addNewItem($event)" [addNew]="true"></di-autocomplete>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 mb-2">
                    <di-textbox placeholder="Quantity" fcName="quantity{{index}}"></di-textbox>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 mb-2">
                    <di-textbox placeholder="Rate" fcName="rate{{index}}" hintMessage="Last rate: 10"></di-textbox>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 mb-2">
                    <di-textbox placeholder="Total" fcName="total{{index}}"></di-textbox>
                </div>
            </div>
        </mat-panel-title>
        <!-- <mat-panel-description>
            Type your name and age
            <mat-icon>account_circle</mat-icon>
        </mat-panel-description> -->
    </mat-expansion-panel-header>

    <div class="row">
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Generic Name" fcName="itemGenericName{{index}}" readonly></di-textbox>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Therapeutics" fcName="itemTherapeutics{{index}}" readonly></di-textbox>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Form" fcName="iteamForm{{index}}" readonly></di-textbox>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Packing Style" fcName="itemPackingStyle{{index}}" readonly></di-textbox>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-select placeholder="Incoterm" fcName="incoterm{{index}}" [options]="incoterms" value="id" dv="value" [isAddNew]="true" (onAddNew)="addNewIncoterm($event)"></di-select>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-select placeholder="Way of Shipmen" fcName="shipmentType{{index}}" [options]="shipmentTypes" value="id" dv="value" [isAddNew]="true" (onAddNew)="addNewShipmentType($event)"></di-select>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Invoice Rate" fcName="invoiceRate{{index}}" hintMessage="Last invoice rate: 9.2"></di-textbox>
        </div>
        <!-- <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Last Invoice Rate" fcName="lastInvoiceRate{{index}}"></di-textbox>
        </div> -->
    </div>
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <di-textarea placeholder="Strength" fcName="strength{{index}}"></di-textarea>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <di-textarea placeholder="Reference Product" fcName="referenceProduct{{index}}"></di-textarea>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <di-textarea placeholder="Special Requirement" fcName="specialRequirement{{index}}"></di-textarea>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <di-textarea placeholder="Shipper" fcName="shipper{{index}}"></di-textarea>
        </div>
    </div>
    <!-- <mat-action-row>
        <button mat-button color="primary" (click)="nextStep()">Next</button>
    </mat-action-row> -->
</mat-expansion-panel>
</di-form>