import { Component, Injector } from '@angular/core';
import { DiBaseSearchComponent } from 'src/app/di-core/base/di.base-search.component';
import { DiSearchColumn } from 'src/app/di-core/components/di-search/di-search.column';
import { ClientCompanySearchDTO } from "../../../search-dto/common.client-company-search.dto";
import { ClientCompanyDTO } from "../../../dto/common.client-company.dto";

@Component({
  selector: 'client-company-search',
  templateUrl: './client-company-search.component.html',
  styleUrls: ['./client-company-search.component.scss']
})
export class ClientCompanySearchComponent extends DiBaseSearchComponent<ClientCompanySearchDTO, ClientCompanyDTO>{
  
  clientCompanySearchDTO: ClientCompanySearchDTO;


  constructor(protected injector: Injector) {
    super(injector);
  }

  diInit(){
    let component = this;
    component.columns = [
      new DiSearchColumn({name: "#", serialNo: true}),
      new DiSearchColumn({name: "Company Name", prop: "name", width: 150}),
      new DiSearchColumn({name: "Company code", prop: "code", width: 150}),
      new DiSearchColumn({name: "Notes", prop: "notes"}),
    ];
  }

}
