import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DiFormService } from '../../service/di-form-service/di-form.service';

@Component({
  selector: 'di-form',
  templateUrl: './di-form.component.html',
  styleUrls: ['./di-form.component.scss']
})
export class DiFormComponent implements OnInit {
  @Input() title: string;
  @Input() formGroup: FormGroup;
  @Input() isSubForm: boolean = false;

  constructor(private formService: DiFormService) {
    let component = this;
   
  }
  ngOnInit(): void {
    let component = this;
  }

  onSubmitForm(event){
    let component = this;
    component.formGroup.markAllAsTouched();
    component.formService.submit(component.formGroup);
  }
  onCancelForm(event){
    let component = this;
    component.formService.cancel(component.formGroup);
  }

  
}
