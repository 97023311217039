import { Component, Injector } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, map, Observable, startWith } from 'rxjs';
import { DiBaseFormComponent } from 'src/app/di-core';
import { DiResponseDTO } from 'src/app/di-core/misc/di.response.dto';
import { ListMasterFormWidgetComponent } from 'src/app/med-erp/common/list-master/list-master-form-widget/list-master-form-widget.component';
import { ListMasterConfig } from 'src/app/med-erp/common/list-master/list-master.config';
import { UomFormComponent } from 'src/app/med-erp/common/uom/uom-form/uom-form.component';
import { ListMasterDTO } from 'src/app/med-erp/dto/common.list-master.dto';
import { UomDTO } from 'src/app/med-erp/dto/common.uom.dto';
import { ItemDTO } from 'src/app/med-erp/dto/inventory.item.dto';
import { ListMasterFormComponent } from "../../../common/list-master/list-master-form/list-master-form.component";

@Component({
  selector: 'item-form',
  templateUrl: './item-form.component.html',
  styleUrls: ['./item-form.component.scss']
})
export class ItemFormComponent extends DiBaseFormComponent<ItemDTO>{
  itemDTO: ItemDTO;
  itemForm: FormGroup;
  itemForms: Array<ListMasterDTO> = [];
  specifications: Array<ListMasterDTO> = [];
  taxTypes: Array<ListMasterDTO> = [];
  packingStyles: Array<ListMasterDTO> = [];
  lbhUoms: Array<UomDTO> = [];

  satusOptions: Array<any> = [
    { name: "Active", value: "ACTIVE" },
    { name: "Inactive", value: "INACTIVE" }
  ]

  private therapeuticses: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>(null);
  public therapeuticses$: Observable<Array<any>> = this.therapeuticses.asObservable();

  constructor(protected injector: Injector, public dialog: MatDialog) {
    super(injector, {modulelName: "item"});
    let component = this;
    component.fetchItemForms();
    component.fetchSpecifications();
    component.fetchTaxTypes();
    component.fetchPackingStyles();
    component.fetchLbhUoms();
  }
  fetchTherapeuticses(searchString?: string) {
    let component = this;
    component.dummyDataService.getAllList("listMaster", { "code": "THERAPEUTICS" }).subscribe((responseDTO: DiResponseDTO) => {
      let therapeuticsesData = responseDTO.body;
      if (!!searchString) {
        therapeuticsesData = therapeuticsesData.filter(
          (option) => option.value.toLowerCase().indexOf(searchString) === 0
        );
      }
      component.therapeuticses.next(therapeuticsesData);
    })

  }
  fetchItemForms() {
    let component = this;
    component.dummyDataService.getAllList("listMaster", { "code": "ITEM_FORM" }).subscribe((responseDTO: DiResponseDTO) => {
      component.itemForms = responseDTO.body;
    })
  }
  fetchSpecifications() {
    let component = this;
    component.dummyDataService.getAllList("listMaster", { "code": "SPECIFICATION" }).subscribe((responseDTO: DiResponseDTO) => {
      component.specifications = responseDTO.body;
    })
  }
  fetchTaxTypes() {
    let component = this;
    component.dummyDataService.getAllList("listMaster", { "code": "TAX_TYPE" }).subscribe((responseDTO: DiResponseDTO) => {
      component.taxTypes = responseDTO.body;
    })
  }
  fetchPackingStyles() {
    let component = this;
    component.dummyDataService.getAllList("listMaster", { "code": "PACKING_STYLE" }).subscribe((responseDTO: DiResponseDTO) => {
      component.packingStyles = responseDTO.body;
    })
  }
  fetchLbhUoms() {
    let component = this;
    component.dummyDataService.getAllList("uom", { "typeDv": "Length" }).subscribe((responseDTO: DiResponseDTO) => {
      component.lbhUoms = responseDTO.body;
    })
  }

  initForm() {
    let component = this;

    component.itemForm.addControl('brandCode', component.fb.control(component.itemDTO.brandCode, [Validators.required, Validators.pattern('[a-zA-Z]+')]));
    component.itemForm.addControl('therapeutics', component.fb.control(component.itemDTO.therapeutics, [Validators.required,]));
    component.itemForm.addControl('iteamForm', component.fb.control(component.itemDTO.iteamForm, [Validators.required,]));
    component.itemForm.addControl('status', component.fb.control(component.itemDTO.status, [Validators.required,]));
    component.itemForm.addControl('brandName', component.fb.control(component.itemDTO.brandName));
    component.itemForm.addControl('genericName', component.fb.control(component.itemDTO.genericName));
    component.itemForm.addControl('specification', component.fb.control(component.itemDTO.specification));
    component.itemForm.addControl('barcode', component.fb.control(component.itemDTO.barcode));
    component.itemForm.addControl('packingStyle', component.fb.control(component.itemDTO.packingStyle));
    component.itemForm.addControl('selfLife', component.fb.control(component.itemDTO.selfLife));
    component.itemForm.addControl('hsn', component.fb.control(component.itemDTO.hsn));
    component.itemForm.addControl('gst', component.fb.control(component.itemDTO.gst));
    component.itemForm.addControl('cess', component.fb.control(component.itemDTO.cess));
    component.itemForm.addControl('taxType', component.fb.control(component.itemDTO.taxType));
    component.itemForm.addControl('length', component.fb.control(component.itemDTO.length));
    component.itemForm.addControl('breadth', component.fb.control(component.itemDTO.breadth));
    component.itemForm.addControl('height', component.fb.control(component.itemDTO.height));
    component.itemForm.addControl('lbhUOM', component.fb.control(component.itemDTO.lbhUOM));
    component.itemForm.addControl('shipperQuantity', component.fb.control(component.itemDTO.shipperQuantity));
    component.itemForm.addControl('registrationCertificate', component.fb.control(component.itemDTO.registrationCertificate));
    component.itemForm.addControl('registrationCertificateValidTillDate', component.fb.control(component.itemDTO.registrationCertificateValidTillDate));
    component.itemForm.addControl('composition', component.fb.control(component.itemDTO.composition));
    component.itemForm.addControl('otherSpecificRequirement', component.fb.control(component.itemDTO.otherSpecificRequirement));



  }

  addNewForm(event) {
    let component = this;
    const dialogRef = this.dialog.open(ListMasterFormWidgetComponent);
    let listMasterFormWidgetComponent: ListMasterFormWidgetComponent = dialogRef.componentInstance;
    listMasterFormWidgetComponent.config = ListMasterConfig.get('item-form');
    listMasterFormWidgetComponent.setDialogRef(dialogRef);
    dialogRef.afterClosed().subscribe(listMasterDTO => {
      console.log(`Dialog result: ${listMasterDTO}`, listMasterDTO);
      if (!!listMasterDTO) {
        listMasterDTO.id = component.itemForms.length;
        component.itemForms.push(listMasterDTO);
        component.itemForm.controls['iteamForm'].setValue(listMasterDTO.id);
      }

    });
  }
  addNewSpecification(event) {
    let component = this;
    const dialogRef = this.dialog.open(ListMasterFormWidgetComponent);
    let listMasterFormWidgetComponent: ListMasterFormWidgetComponent = dialogRef.componentInstance;
    listMasterFormWidgetComponent.config = ListMasterConfig.get('specification');
    listMasterFormWidgetComponent.setDialogRef(dialogRef);
    dialogRef.afterClosed().subscribe(listMasterDTO => {
      if (!!listMasterDTO) {
        listMasterDTO.id = component.specifications.length;
        component.specifications.push(listMasterDTO);
        component.itemForm.controls['specification'].setValue(listMasterDTO.id);
      }

    });
  }

  addNewtaxType(event) {
    let component = this;
    const dialogRef = this.dialog.open(ListMasterFormWidgetComponent);
    let listMasterFormWidgetComponent: ListMasterFormWidgetComponent = dialogRef.componentInstance;
    listMasterFormWidgetComponent.config = ListMasterConfig.get('tax-type');
    listMasterFormWidgetComponent.setDialogRef(dialogRef);
    dialogRef.afterClosed().subscribe(listMasterDTO => {
      if (!!listMasterDTO) {
        listMasterDTO.id = component.taxTypes.length;
        component.taxTypes.push(listMasterDTO);
        component.itemForm.controls['taxType'].setValue(listMasterDTO.id);
      }

    });
  }

  addNewPackingStyle(event) {
    let component = this;
    const dialogRef = this.dialog.open(ListMasterFormWidgetComponent);
    let listMasterFormWidgetComponent: ListMasterFormWidgetComponent = dialogRef.componentInstance;
    listMasterFormWidgetComponent.config = ListMasterConfig.get('packing-style');
    listMasterFormWidgetComponent.setDialogRef(dialogRef);
    dialogRef.afterClosed().subscribe(listMasterDTO => {
      if (!!listMasterDTO) {
        listMasterDTO.id = component.packingStyles.length;
        component.packingStyles.push(listMasterDTO);
        component.itemForm.controls['packingStyle'].setValue(listMasterDTO.id);
      }

    });
  }

  addNewLbhUom(event) {
    let component = this;
    const dialogRef = this.dialog.open(UomFormComponent);
    let uomFormComponent: UomFormComponent = dialogRef.componentInstance;
    uomFormComponent.setDialogRef(dialogRef);
    dialogRef.afterClosed().subscribe(uomDTO => {
      if (!!uomDTO) {
        uomDTO.id = component.lbhUoms.length;
        component.lbhUoms.push(uomDTO);
        component.itemForm.controls['lbhUOM'].setValue(uomDTO.id);
      }

    });
  }
}



