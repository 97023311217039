import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/med-erp/dashboard',
    title: 'MENU.HOME.TEXT',
    icon: 'home',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '',
    title: 'MENU.SALES.TEXT',
    icon: '',
    class: 'header',
    groupTitle: true,
    submenu: []
  },
  {
    path: '',
    title: 'MENU.SALES.TEXT',
    icon: 'shopping-bag',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: '/med-erp/customer-order/search',
        title: 'MENU.SALES.CUSTOMER_ORDER.TEXT',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/med-erp/client/search',
        title: 'MENU.SALES.CLIENT.TEXT',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/med-erp/client-company/search',
        title: 'MENU.SALES.CLIENT_COMPANY.TEXT',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'MENU.MASTERS.TEXT',
    icon: '',
    class: 'header',
    groupTitle: true,
    submenu: []
  },
  {
    path: '',
    title: 'MENU.MASTERS.COMMON.TEXT',
    icon: 'monitor',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: '/med-erp/country/search',
        title: 'MENU.MASTERS.COMMON.COUNTRY.TEXT',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/med-erp/list-master/gender/search',
        title: 'MENU.MASTERS.COMMON.GENDER.TEXT',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/med-erp/list-master/department/search',
        title: 'MENU.MASTERS.COMMON.DEPARTMENT.TEXT',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/med-erp/list-master/designation/search',
        title: 'MENU.MASTERS.COMMON.DESIGNATION.TEXT',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/med-erp/list-master/shipment-type/search',
        title: 'MENU.MASTERS.COMMON.SHIPMENT_TYPE.TEXT',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/med-erp/list-master/incoterm/search',
        title: 'MENU.MASTERS.COMMON.INCOTERM.TEXT',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/med-erp/list-master/packing-style/search',
        title: 'MENU.MASTERS.COMMON.PACKING_STYLE.TEXT',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/med-erp/list-master/therapeutics/search',
        title: 'MENU.MASTERS.COMMON.THERAPEUTICS.TEXT',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/med-erp/list-master/specification/search',
        title: 'MENU.MASTERS.COMMON.SPECIFICATION.TEXT',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/med-erp/list-master/item-form/search',
        title: 'MENU.MASTERS.COMMON.ITEM_FORM.TEXT',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/med-erp/list-master/tax-type/search',
        title: 'MENU.MASTERS.COMMON.TAX_TYPE.TEXT',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/med-erp/list-master/uom-type/search',
        title: 'MENU.MASTERS.COMMON.UOM_TYPE.TEXT',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/med-erp/uom/search',
        title: 'MENU.MASTERS.COMMON.UOM.TEXT',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/med-erp/currency/search',
        title: 'MENU.MASTERS.COMMON.CURRENCY.TEXT',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/med-erp/item/search',
        title: 'MENU.MASTERS.INVENTORY.ITEM.TEXT',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  } 
];
