import { Component, Injector } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DiBaseFormComponent } from 'src/app/di-core/base/di.base-form.component';
import { DiResponseDTO } from 'src/app/di-core/misc/di.response.dto';
import { ListMasterDTO } from 'src/app/med-erp/dto/common.list-master.dto';
import { UomDTO } from 'src/app/med-erp/dto/common.uom.dto';
import { ListMasterFormWidgetComponent } from '../../list-master/list-master-form-widget/list-master-form-widget.component';
import { ListMasterConfig } from '../../list-master/list-master.config';

@Component({
  selector: 'uom-form',
  templateUrl: './uom-form.component.html',
  styleUrls: ['./uom-form.component.scss']
})
export class UomFormComponent extends DiBaseFormComponent<UomDTO>{
  uomDTO: UomDTO;
  uomForm: FormGroup;
  types: Array<ListMasterDTO>= [];

  satusOptions: Array<any> = [
    {name: "Active", value: "ACTIVE"},
    {name: "Inactive", value: "INACTIVE"}
  ]

  constructor(protected injector: Injector, public dialog: MatDialog) {
    super(injector);
    let component = this;
    component.fetchTypes();
  }


  initForm() {
    let component = this;
 
    component.uomForm.addControl('name', component.fb.control(component.uomDTO.name, [Validators.required, Validators.pattern('[a-zA-Z]+')]));
    component.uomForm.addControl('dv', component.fb.control(component.uomDTO.dv, [Validators.required,]));
    component.uomForm.addControl('type', component.fb.control(component.uomDTO.type, [Validators.required,]));
    component.uomForm.addControl('status', component.fb.control(component.uomDTO.status, [Validators.required,]));
    component.uomForm.addControl('notes', component.fb.control(component.uomDTO.notes)); 

  }

  fetchTypes() {
    let component = this;
    component.dummyDataService.getAllList("listMaster", {"code": "UOM_TYPE"}).subscribe((responseDTO: DiResponseDTO) => {
      component.types = responseDTO.body;
      console.log("component.types ", component.types);
    })
  }

  addNewType(event){
    let component = this;
    const dialogRef = this.dialog.open(ListMasterFormWidgetComponent);
    let listMasterFormWidgetComponent: ListMasterFormWidgetComponent = dialogRef.componentInstance;
    listMasterFormWidgetComponent.config = ListMasterConfig.get('packing-style');
    listMasterFormWidgetComponent.setDialogRef(dialogRef);
    dialogRef.afterClosed().subscribe(listMasterDTO => {
      if(!!listMasterDTO){
        listMasterDTO.id = component.types.length;
        component.types.push(listMasterDTO);
        component.uomForm.controls['type'].setValue(listMasterDTO.id);
      }

    });
  }
}
