import { Component, ViewChild, Injector, Optional, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Params } from '@angular/router';
import { DiResponseDTO } from '../misc/di.response.dto';
import { DiBaseComponent } from './di.base.component';
import { DiBaseDTO } from './di.base.dto';
import { DummyDataService } from "../../med-erp/service/dummy-data-service/dummy-data.service";
import { DiBaseSearchDTO } from './di.base-search.dto';
import { DiPageDTO } from '../misc/di.page.dto';
import { DiSearchComponent } from '../components/di-search/di-search.component';
import { DiSearchService } from '../service/di-search-service/di-search.service';

@Component({
    template: '',
})
export abstract class DiBaseSearchComponent<T extends DiBaseSearchDTO, S extends DiBaseDTO> extends DiBaseComponent {
    // private _id: number;
    // public isEdit = false;
    // public isCreate = true;
    public pageDTO: DiPageDTO<S> = {} as DiPageDTO<S>;
    public columns: Array<any> = [];
    protected fb: FormBuilder;
    protected searchDtoName: string;
    protected searchFormName: string;
    private dummyDataService: DummyDataService;
    private searchService: DiSearchService;
    @Output() public onSearch = new EventEmitter();

    @ViewChild(DiSearchComponent, { static: false }) searchComponent: DiSearchComponent;

    constructor(protected injector: Injector, @Optional() protected options?: Object) {
        super(injector, options);
        let component = this;
        component.fb = injector.get(FormBuilder);
        component.dummyDataService = injector.get(DummyDataService);
        component.searchService = injector.get(DiSearchService);
        component.searchDtoName = component.modulelName + "SearchDTO";
        component.searchFormName = component.modulelName + "SearchForm";


        component[component.searchDtoName] = component.getSearchDTO();
    }

    ngOnInit(): void {
        let component = this;
        if (typeof component["initSearchForm"] == "function") {
            component["initSearchForm"]();
        }
        if (typeof component["diPreSearch"] == "function") {
            component["diPreSearch"]();
        }
        component.search();
        if (typeof component["diInit"] == "function") {
            component["diInit"]();
        }
        component.searchService.onPageChange$.subscribe(searchComponent=>{
            if(!!searchComponent && searchComponent==component.searchComponent){
                component.changePage(searchComponent.pageInfo);
            }
        });
        component.searchService.onEdit$.subscribe(searchComponent=>{
            if(!!searchComponent && searchComponent==component.searchComponent){
                component.edit(searchComponent.selectedId);
            }
        });
        component.searchService.onNew$.subscribe(searchComponent=>{
            if(!!searchComponent && searchComponent==component.searchComponent){
                component.new();
            }
        });
    }

    ngAfterViewInit() {
        let component = this;
    }
    search() {
        let component = this;
        component.dummyDataService.search(component.modulelName, component[component.searchDtoName]).subscribe((responseDTO: DiResponseDTO) => {
            component.pageDTO = responseDTO.body;
        })
    }
    new() {
        let component = this;
        component.router.navigateByUrl(`${component.baseModuleURL}/form`);
    }
    edit(id: number) {
        let component = this;
        component.router.navigateByUrl(`${component.baseModuleURL}/form/${id}`);
    }
    changePage(pageInfo) {
        let component = this;
        component[component.searchDtoName].pageNo = pageInfo.offset;
        component.search();
    }

    protected getSearchDTO<T extends DiBaseSearchDTO>() {
        return {
            pageNo: 0,
            pageSize: 10,
            totalRecords: -1
        } as T;
    }



}
