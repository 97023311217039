<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <div class="card">
          <div class="header">
            <h2>Fill Input Style</h2>
          </div>
          <div class="body">
            <div class="example-container">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Input</mat-label>
                <input matInput>
              </mat-form-field>
              <br>
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Select</mat-label>
                <mat-select>
                  <mat-option value="option1">Option 1</mat-option>
                  <mat-option value="option2">Option 2</mat-option>
                </mat-select>
              </mat-form-field>
              <br>
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Textarea</mat-label>
                <textarea matInput></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <div class="card">
          <div class="header">
            <h2>Outline Input Style</h2>
          </div>
          <div class="body">
            <div class="example-container">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Input</mat-label>
                <input matInput>
              </mat-form-field>
              <br>
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Select</mat-label>
                <mat-select>
                  <mat-option value="option1">Option 1</mat-option>
                  <mat-option value="option2">Option 2</mat-option>
                </mat-select>
              </mat-form-field>
              <br>
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Textarea</mat-label>
                <textarea matInput></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="header">
            <h2>Form field with label</h2>
          </div>
          <div class="body">
            <div class="example-container">
              <form [formGroup]="labelForm">
                <div class="row">
                  <div class="col-4">
                    <mat-form-field class="example-full-width" appearance="fill"
                      [hideRequiredMarker]="hideRequiredControl.value" [floatLabel]="false">
                      <input matInput placeholder="Simple placeholder" required>
                    </mat-form-field>
                  </div>
                  <div class="col-4">
                    <mat-form-field class="example-full-width" appearance="fill" [floatLabel]="floatLabelControl.value">
                      <mat-label>Both a label and a placeholder</mat-label>
                      <input matInput placeholder="Simple placeholder">
                    </mat-form-field>
                  </div>
                  <div class="col-4">
                    <mat-form-field class="example-full-width" appearance="fill"
                      [hideRequiredMarker]="hideRequiredControl.value" [floatLabel]="floatLabelControl.value">
                      <mat-select required>
                        <mat-option>-- None --</mat-option>
                        <mat-option value="option1">Option 1</mat-option>
                        <mat-option value="option2">Option 2</mat-option>
                      </mat-select>
                      <mat-label>
                        <mat-icon>favorite</mat-icon> <b> Fancy</b> <i> label</i>
                      </mat-label>
                    </mat-form-field>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="header">
            <h2>File Upload</h2>
          </div>
          <div class="body">
            <div class="example-container">
              <form [formGroup]="fileUploadForm">
                <div class="row">
                  <div class="col-4">
                    <mat-label>Upload File</mat-label>
                    <app-file-upload formControlName="singleUpload"></app-file-upload>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <div class="card">
          <div class="header">
            <h2>Check Box</h2>
          </div>
          <div class="body">
            <h6>Checkbox configuration</h6>
            <section class="example-section">
              <mat-checkbox class="example-margin" [(ngModel)]="checked">Checked</mat-checkbox>
              <mat-checkbox class="example-margin" [(ngModel)]="indeterminate">Indeterminate
              </mat-checkbox>
            </section>
            <section class="example-section">
              <label class="example-margin">Align:</label>
              <mat-radio-group [(ngModel)]="labelPosition">
                <mat-radio-button class="example-margin" value="after">After</mat-radio-button>
                <mat-radio-button class="example-margin" value="before">Before
                </mat-radio-button>
              </mat-radio-group>
            </section>
            <section class="example-section">
              <mat-checkbox class="example-margin" [(ngModel)]="disabled">Disabled</mat-checkbox>
            </section>
            <h6 class="example-h4">Result</h6>
            <section class="example-section">
              <mat-checkbox class="example-margin" [(ngModel)]="checked" [(indeterminate)]="indeterminate"
                [labelPosition]="labelPosition" [disabled]="disabled">
                I'm a checkbox
              </mat-checkbox>
            </section>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <div class="card">
          <div class="header">
            <h2>Radio Buttons</h2>
          </div>
          <div class="body">
            <label id="example-radio-group-label">Pick your favorite season</label>
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
              [(ngModel)]="favoriteSeason">
              <mat-radio-button class="example-radio-button ms-0" *ngFor="let season of seasons" [value]="season">
                {{season}}
              </mat-radio-button>
            </mat-radio-group>
            <div>Your favorite season is: {{favoriteSeason}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="header">
            <h2>Datepicker</h2>
          </div>
          <div class="body">
            <div class="row">
              <div class="col-5">
                <h6>Basic datepicker</h6>
                <mat-form-field class="example-full-width">
                  <mat-label>Choose a date</mat-label>
                  <input matInput [matDatepicker]="picker">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-2"></div>
              <div class="col-5">
                <h6>Datepicker start date</h6>
                <mat-form-field class="example-full-width">
                  <mat-label>Choose a date</mat-label>
                  <input matInput [matDatepicker]="picker2">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2 startView="year" [startAt]="startDate"></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-5">
                <h6>Datepicker selected value</h6>
                <mat-form-field class="example-full-width">
                  <mat-label>Angular forms</mat-label>
                  <input matInput [matDatepicker]="picker3" [formControl]="date">
                  <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                  <mat-datepicker #picker3></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-2"></div>
              <div class="col-5">
                <h6>Datepicker with min & max validation</h6>
                <mat-form-field class="example-full-width">
                  <mat-label>Choose a date</mat-label>
                  <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker4">
                  <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                  <mat-datepicker #picker4></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-5">
                <h6>Datepicker with filter validation</h6>
                <p>Prevent Saturday and Sunday from being selected</p>
                <mat-form-field class="example-full-width">
                  <mat-label>Choose a date</mat-label>
                  <input matInput [matDatepickerFilter]="myFilter" [matDatepicker]="picker5">
                  <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                  <mat-datepicker #picker5></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-2"></div>
              <div class="col-5">
                <h6>Open Datepicker on button click</h6>
                <p>Open date picket on button click</p>
                <mat-form-field>
                  <input matInput [matDatepicker]="picker6" placeholder="Choose a date">
                  <mat-datepicker #picker6></mat-datepicker>
                </mat-form-field>
                <button mat-flat-button color="primary" (click)="picker6.open()" class="msl-3">Open</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="header">
            <h2>Datetime picker</h2>
          </div>
          <div class="body">
            <div class="row">
              <div class="col-5">
                <h6>Basic datepicker</h6>
                <mat-form-field class="example-full-width mb-3">
                  <mat-label>Choose a date</mat-label>
                  <input matInput [owlDateTimeTrigger]="date1" [owlDateTime]="date1">
                  <owl-date-time #date1></owl-date-time>
                </mat-form-field>
              </div>
              <div class="col-2"></div>
              <div class="col-5">
                <h6>Datetime picker with icon</h6>
                <mat-form-field class="example-full-width mb-3">
                  <mat-label>Choose a date</mat-label>
                  <input matInput [owlDateTimeTrigger]="date2" [owlDateTime]="date2">
                  <mat-icon matSuffix [owlDateTimeTrigger]="date2" class="date-icon">today</mat-icon>
                  <owl-date-time #date2></owl-date-time>
                </mat-form-field>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-5">
                <h6>Time Picker</h6>
                <mat-form-field class="example-full-width mb-3">
                  <mat-label>Choose a time</mat-label>
                  <input matInput [owlDateTimeTrigger]="time1" [owlDateTime]="time1">
                  <mat-icon matSuffix [owlDateTimeTrigger]="time1" class="date-icon">schedule</mat-icon>
                  <owl-date-time [pickerType]="'timer'" #time1></owl-date-time>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="header">
            <h2>Select Item</h2>
          </div>
          <div class="body">
            <div class="row">
              <div class="col-5">
                <h6>Basic mat-select</h6>
                <mat-form-field class="example-full-width">
                  <mat-label>Favorite food</mat-label>
                  <mat-select>
                    <mat-option *ngFor="let food of foods" [value]="food.value">
                      {{food.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-2"></div>
              <div class="col-5">
                <h6>Basic native select</h6>
                <mat-form-field class="example-full-width">
                  <mat-label>Cars</mat-label>
                  <select matNativeControl required>
                    <option value="volvo">Volvo</option>
                    <option value="saab">Saab</option>
                    <option value="mercedes">Mercedes</option>
                    <option value="audi">Audi</option>
                  </select>
                </mat-form-field>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-5">
                <h6>Select Binding</h6>
                <form>
                  <mat-form-field class="example-full-width">
                    <mat-label>Favorite food</mat-label>
                    <mat-select [(ngModel)]="selectedValue" name="food">
                      <mat-option *ngFor="let food of foods" [value]="food.value">
                        {{food.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <p> Selected food: {{selectedValue}} </p>
                </form>
              </div>
              <div class="col-2"></div>
              <div class="col-5">
                <h6>Native select binding</h6>
                <mat-form-field class="example-full-width">
                  <mat-label>Favorite car</mat-label>
                  <select matNativeControl [(ngModel)]="selectedCar" name="car">
                    <option value="" selected></option>
                    <option *ngFor="let car of cars" [value]="car.value">
                      {{car.viewValue}}
                    </option>
                  </select>
                </mat-form-field>
                <p> Selected car: {{selectedCar}} </p>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-5">
                <h6>Select Item Validation</h6>
                <mat-form-field class="example-full-width">
                  <mat-label>Favorite animal</mat-label>
                  <mat-select [formControl]="animalControl" required>
                    <mat-option>Select Item</mat-option>
                    <mat-option *ngFor="let animal of animals" [value]="animal">
                      {{animal.name}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="animalControl.hasError('required')">Please choose an animal
                  </mat-error>
                  <mat-hint>{{animalControl.value?.sound}}</mat-hint>
                </mat-form-field>
              </div>
              <div class="col-2"></div>
              <div class="col-5">
                <h6>Native select item validation</h6>
                <mat-form-field class="example-full-width">
                  <mat-label>Select your car (required)</mat-label>
                  <select matNativeControl required [formControl]="selectFormControl">
                    <option label="--select something --"></option>
                    <option value="saab">Saab</option>
                    <option value="mercedes">Mercedes</option>
                    <option value="audi">Audi</option>
                  </select>
                  <mat-error *ngIf="selectFormControl.hasError('required')">
                    This field is required
                  </mat-error>
                  <mat-hint>Select your favorite car here</mat-hint>
                </mat-form-field>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-5">
                <h6>Disable Select Item Option</h6>
                <mat-form-field class="example-full-width">
                  <mat-label>Choose an option</mat-label>
                  <mat-select [disabled]="disableSelect.value">
                    <mat-option value="option1">Option 1</mat-option>
                    <mat-option value="option2" disabled>Option 2 (disabled)</mat-option>
                    <mat-option value="option3">Option 3</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-2"></div>
              <div class="col-5">
                <h6>Native Disable Select Item Option</h6>
                <mat-form-field class="example-full-width">
                  <mat-label>Choose an option</mat-label>
                  <select matNativeControl [disabled]="disableSelect.value">
                    <option value="" selected></option>
                    <option value="volvo">Volvo</option>
                    <option value="saab" disabled>Saab</option>
                    <option value="mercedes">Mercedes</option>
                    <option value="audi">Audi</option>
                  </select>
                </mat-form-field>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-5">
                <h6>Multiple Select Item</h6>
                <mat-form-field class="example-full-width">
                  <mat-label>Toppings</mat-label>
                  <mat-select [formControl]="toppings" multiple>
                    <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-2"></div>
              <div class="col-5">
                <h6>Select with option groups</h6>
                <mat-form-field class="example-full-width">
                  <mat-label>Pokemon</mat-label>
                  <mat-select [formControl]="pokemonControl">
                    <mat-option>-- None --</mat-option>
                    <mat-optgroup *ngFor="let group of pokemonGroups" [label]="group.name" [disabled]="group.disabled">
                      <mat-option *ngFor="let pokemon of group.pokemon" [value]="pokemon.value">
                        {{pokemon.viewValue}}
                      </mat-option>
                    </mat-optgroup>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-5">
                <h6>Select with custom trigger text</h6>
                <mat-form-field class="example-full-width">
                  <mat-select placeholder="Toppings" [formControl]="toppings" multiple>
                    <mat-select-trigger>
                      {{toppings.value ? toppings.value[0] : ''}}
                      <span *ngIf="toppings.value?.length > 1" class="example-additional-selection">
                        (+{{toppings.value.length - 1}}
                        {{toppings.value?.length === 2 ? 'other' : 'others'}})
                      </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-2"></div>
              <div class="col-5">
                <h6>Select with a custom ErrorStateMatcher</h6>
                <mat-form-field class="example-full-width">
                  <mat-label>Choose one</mat-label>
                  <mat-select [formControl]="selected" [errorStateMatcher]="matcher">
                    <mat-option>Clear</mat-option>
                    <mat-option value="valid">Valid option</mat-option>
                    <mat-option value="invalid">Invalid option</mat-option>
                  </mat-select>
                  <mat-hint>Errors appear instantly!</mat-hint>
                  <mat-error *ngIf="selected.hasError('required')">You must make a selection
                  </mat-error>
                  <mat-error *ngIf="selected.hasError('pattern') && !selected.hasError('required')">
                    Your selection is invalid
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="header">
            <h2>Slide Toggle</h2>
          </div>
          <div class="body">
            <div class="row">
              <div class="col-6">
                <h6>Basic slide-toggles</h6>
                <mat-slide-toggle>Slide me!</mat-slide-toggle>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-6">
                <h6>Color slide-toggles</h6>
                <section class="example-section">
                  <label class="example-margin">Color:</label>
                  <mat-radio-group [(ngModel)]="color">
                    <mat-radio-button class="example-margin" value="primary">
                      Primary
                    </mat-radio-button>
                    <mat-radio-button class="example-margin" value="accent">
                      Accent
                    </mat-radio-button>
                    <mat-radio-button class="example-margin" value="warn">
                      Warn
                    </mat-radio-button>
                  </mat-radio-group>
                </section>
                <section class="example-section">
                  <mat-checkbox class="example-margin" [(ngModel)]="checked">Checked
                  </mat-checkbox>
                </section>
                <section class="example-section">
                  <mat-checkbox class="example-margin" [(ngModel)]="disabled">Disabled
                  </mat-checkbox>
                </section>
                <h4 class="example-h2">Result</h4>
                <section class="example-section">
                  <mat-slide-toggle class="example-margin" [color]="color" [checked]="checked" [disabled]="disabled">
                    Slide me!
                  </mat-slide-toggle>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
