<di-form [formGroup]="clientCompanyForm" title="Client Company">
    <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Company Name" fcName="name"></di-textbox>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Company Code" fcName="code"></di-textbox>
        </div>

        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-select placeholder="Status" fcName="status" [options]="satusOptions" value="value" dv="name"></di-select>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Client Name" fcName="name"></di-textbox>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Client Code" fcName="code"></di-textbox>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Address Line 1" fcName="addressLine1"></di-textbox>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Address Line 2" fcName="addressLine2"></di-textbox>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="City" fcName="addressCityId"></di-textbox>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="State" fcName="addressStateId"></di-textbox>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Country" fcName="addressCountryId"></di-textbox>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Zipcode" fcName="addressZipcode"></di-textbox>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="City" fcName="contactPerson"></di-textbox>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="State" fcName="email"></di-textbox>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Country" fcName="phoneNumber"></di-textbox>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Zipcode" fcName="taxId"></di-textbox>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <di-textarea placeholder="Notes" fcName="notes"></di-textarea>
        </div>
    </div>
</di-form>