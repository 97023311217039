import { Component, OnInit, Input, ViewChild, TemplateRef, Host } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { BehaviorSubject, Observable } from 'rxjs';
import { DiPageDTO } from '../../misc/di.page.dto';
import { DiSearchService } from "../../service/di-search-service/di-search.service";

@Component({
  selector: 'di-search',
  templateUrl: './di-search.component.html',
  styleUrls: ['./di-search.component.scss']
})
export class DiSearchComponent implements OnInit {
  ColumnMode = ColumnMode;

  @Input() title: string;
  @Input() searchPlaceholder: string;
  @Input() isNew: boolean = true;
  @Input() isEdit: boolean = true;
  @Input() isView: boolean = false;
  @Input() columns: Array<any> = [];
  @Input() pageDTO: DiPageDTO<any>; 

  @ViewChild('tmplSerialNo', { read: TemplateRef, static: true })
  tmplSerialNo: TemplateRef<any>;
   
  public pageInfo : any;
  public selectedId : number;

  constructor(private searchService: DiSearchService) {
    let component = this;
   
  }

  ngOnInit(): void {
    let component = this;
    component.columns.forEach(column=>{
      if(column.serialNo){
        column.cellTemplate = component.tmplSerialNo;
        column.headerClass = column.cellClass = "di-tx-center";
        column.width = 50;
        column.canAutoResize = false;
      }
    })
  }
  changePage(pageInfo) {
    let component = this;
    component.pageInfo =  pageInfo;
    component.searchService.pageChange(component);
  }
  new(){
    let component = this;
    component.searchService.new(component);
  }
  edit(selectedId: number){
    let component = this;
    component.selectedId = selectedId;
    component.searchService.edit(component);
  }
  filterDatatable(event) {

  }
}
