<di-form [formGroup]="currencyForm" title="Currency">
    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Currency Name" fcName="name"></di-textbox>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Currency Code" fcName="code"></di-textbox>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
            <di-select placeholder="Status" fcName="status" [options]="satusOptions" value="value" dv="name"></di-select>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Symble" fcName="symbole"></di-textbox>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <di-textarea placeholder="Notes" fcName="notes"></di-textarea>
        </div>
    </div>
</di-form>