<di-form [formGroup]="customerOrderForm" title="Customer Order">
    <div class="row">
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-select placeholder="Order Type" fcName="type" [options]="orderTypeOptions" value="value" dv="name"></di-select>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
            <di-select placeholder="Company" fcName="companyId" [options]="companyOptions" value="value" dv="name"></di-select>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Order No." fcName="no"></di-textbox>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-autocomplete placeholder="Country" fcName="countryId" [options$]="countries$" (onLookup)="fetchCountries($event)" dv="name"></di-autocomplete>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
            <di-autocomplete placeholder="Client" fcName="clientId" [options$]="clients$" (onLookup)="fetchClients($event)" dv="name"></di-autocomplete>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Order Date" fcName="date"></di-textbox>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
            <di-select placeholder="Client Company" fcName="clientCompanyId" [options]="clientCompanyDTOs" value="id" dv="name"></di-select>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Timeline" fcName="timeline"></di-textbox>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Temperature Cargo" fcName="temperatureCargo"></di-textbox>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Port of Loading" fcName="clientCompanyId"></di-textbox>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
            <di-textbox placeholder="Port of Discharge" fcName="dischargePort"></di-textbox>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <mat-accordion class="example-headers-align">
                <ng-container #vcCustomerOrderDetails></ng-container>
                
                <!-- <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Personal data
                        </mat-panel-title>
                        <mat-panel-description>
                            Type your name and age
                            <mat-icon>account_circle</mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                            <di-textbox placeholder="Client Company" fcName="clientCompanyId"></di-textbox>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
                            <di-textbox placeholder="Timeline" fcName="timeline"></di-textbox>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
                            <di-textbox placeholder="Temperature Cargo" fcName="temperatureCargo"></di-textbox>
                        </div>
                    </div>

                    <mat-action-row>
                        <button mat-button color="primary" (click)="nextStep()">Next</button>
                    </mat-action-row>
                </mat-expansion-panel> -->
            </mat-accordion>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <button mat-button color="primary" (click)="addNewDetail()" type="button">Add</button>
        </div>
    </div>
    
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <di-textarea placeholder="Required Documents" fcName="requiredDocumentNotes"></di-textarea>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <di-textarea placeholder="Remarks" fcName="remarks"></di-textarea>
        </div>
    </div>
</di-form>
<ng-template #tmplCustomerOrderDetail let-customerOrderForm let-customerOrderDetailDTO="customerOrderDetailDTO" let-toggleDetail$="toggleDetail$" let-index="index" let-orderDetailTypes="orderDetailTypes">
    <customer-order-detail [customerOrderForm]="customerOrderForm" [customerOrderDetailDTO]="customerOrderDetailDTO" [toggleDetail$]="toggleDetail$" [index]="index" [orderDetailTypes]="orderDetailTypes"></customer-order-detail>
</ng-template>
