import { Component, Injector } from '@angular/core';
import { DiBaseSearchComponent } from 'src/app/di-core/base/di.base-search.component';
import { DiSearchColumn } from 'src/app/di-core/components/di-search/di-search.column';
import { IteamSearchDTO } from "../../../search-dto/inventory.iteam-search.dto";
import { ItemDTO } from "../../../dto/inventory.item.dto";

@Component({
  selector: 'item-search',
  templateUrl: './item-search.component.html',
  styleUrls: ['./item-search.component.scss']
})
export class ItemSearchComponent extends DiBaseSearchComponent<IteamSearchDTO, ItemDTO>{
  
  iteamSearchDTO: IteamSearchDTO;


  constructor(protected injector: Injector) {
    super(injector);
  }

  diInit(){
    let component = this;
    component.columns = [
      new DiSearchColumn({name: "#", serialNo: true}),
      new DiSearchColumn({name: "Brand Code", prop: "brandCode", width: 150}),
      new DiSearchColumn({name: "Brand Name", prop: "brandName", width: 150}),
      new DiSearchColumn({name: "Generic Name", prop: "genericName"}),
    ];
  }

}
