import { Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ListMasterConfig } from '../list-master.config';

@Component({
  selector: 'list-master-form',
  templateUrl: './list-master-form.component.html',
  styleUrls: ['./list-master-form.component.scss']
})
export class ListMasterFormComponent {
  private type: string;

  private options: Map<string, any> = new Map();
  config: any ={}

  @ViewChild('tmplListMasterFormWidget', { read: TemplateRef, static: true })
  tmplListMasterFormWidget: TemplateRef<any>;

  @ViewChild('vcListMasterFormWidget', { read: ViewContainerRef, static: true })
  vcListMasterFormWidget: ViewContainerRef;

  constructor(private route: ActivatedRoute) {
    let component = this;
    component.route.params.subscribe((params: Params) => {
      if (!!params['type']) {
        component.type = params['type'];
        component.config = ListMasterConfig.get(component.type);
        component.render();
      }
    });
  }
  ngOnInit(): void {
    let component = this;
    component.render();
  }

  render(){
    let component = this;
    if(!!component.vcListMasterFormWidget){
      component.vcListMasterFormWidget.clear();
      component.vcListMasterFormWidget.createEmbeddedView(component.tmplListMasterFormWidget);
    }
  }
}
