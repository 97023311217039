import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiTextboxComponent } from './components/form-inputs/di-textbox/di-textbox.component';
import { DiFormComponent } from './components/di-form/di-form.component';
import { FormsRoutingModule } from '../forms/forms-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgxMaskModule } from 'ngx-mask';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MedErpRoutingModule } from '../med-erp/med-erp-routing.module';
import { DiTextareaComponent } from './components/form-inputs/di-textarea/di-textarea.component';
import { DiSearchComponent } from './components/di-search/di-search.component';
import { DiSelectComponent } from './components/form-inputs/di-select/di-select.component';
import { DiDatepickerComponent } from './components/form-inputs/di-datepicker/di-datepicker.component';
import { DiFileUploadComponent } from './components/form-inputs/di-file-upload/di-file-upload.component';
import { DiAutocompleteComponent } from './components/form-inputs/di-autocomplete/di-autocomplete.component';


@NgModule({
  declarations: [
    DiTextboxComponent,
    DiFormComponent,
    DiTextareaComponent,
    DiSearchComponent,
    DiSelectComponent,
    DiDatepickerComponent,
    DiFileUploadComponent,
    DiAutocompleteComponent
  ],
  imports: [
    CommonModule,
    FormsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSortModule,
    MatStepperModule,
    MatToolbarModule,
    MatTooltipModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxMaskModule,
    NgScrollbarModule,
    NgxDatatableModule,
    MatSnackBarModule,
    MedErpRoutingModule,
  ],
  exports:[
    DiTextboxComponent,
    DiTextareaComponent,
    DiSelectComponent,
    DiDatepickerComponent,
    DiFileUploadComponent,
    DiAutocompleteComponent,
    DiFormComponent,
    DiSearchComponent,

  ]
})
export class DiCoreModule { }
