<ng-container *ngIf="!!formGroup" [formGroup]="formGroup">
<mat-form-field class="example-full-width" appearance="outline">
    <mat-label>{{placeholder}}</mat-label>
    <input matInput [matDatepicker]="picker" [formControlName]="fcName">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error *ngIf="formGroup.get(fcName).hasError('required')">
        required
    </mat-error>
  </mat-form-field>
</ng-container>  