import { Component } from '@angular/core';

@Component({
  selector: 'pre-login-layout',
  templateUrl: './pre-login-layout.component.html',
  styleUrls: ['./pre-login-layout.component.scss']
})
export class PreLoginLayoutComponent {

}
