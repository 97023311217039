export class ListMasterConfig{
    private static options: any ={
        "gender": {
            code: "GENDER",
            title: "Gender",
            valueName: "Gender Name",
          },
          "department": {
            code: "DEPARTMENT",
            title: "Department",
            valueName: "Department Name",
          },
          "designation": {
            code: "DESIGNATION",
            title: "Designation",
            valueName: "Designation Name",
          },
          "shipment-type": {
            code: "SHIPMENT_TYPE",
            title: "Shipment Type",
            valueName: "Type",
          },
          "incoterm": {
            code: "INCOTERM",
            title: "Incoterm",
            valueName: "Incoterm",
          },
          "packing-style": {
            code: "PACKING_STYLE",
            title: "Packing Style",
            valueName: "Packing Style",
          },
          "therapeutics": {
            code: "THERAPEUTICS",
            title: "Therapeutics",
            valueName: "Therapeutics",
          },
          "specification": {
            code: "SPECIFICATION",
            title: "Specification",
            valueName: "Specification Name",
          },
          "item-form": {
            code: "ITEM_FORM",
            title: "Item Form",
            valueName: "Item Form",
          },
          "tax-type": {
            code: "TAX_TYPE",
            title: "Tax Type",
            valueName: "Tax Type",
          },
          "uom-type": {
            code: "UOM_TYPE",
            title: "Uom Type",
            valueName: "Uom Type",
          }
    }
    
    public static get(type: string){
        return ListMasterConfig.options[type];
    }
}