<div class="container-fluid p-0">
    <div class="row g-0">
      <div class="col-lg-4">
        <div class="auth-content p-4 d-flex align-items-center min-vh-100">
          <div class="w-100">
            <div class="row justify-content-center">
              <div class="col-lg-9">
                <div>
                  <div class="text-center">
                    <div>
                      <a routerLink="" class="logo-full"><img src="assets/images/med-erp/logo.png" alt="logo-full"></a>
                    </div>

                    <h4 class="font-20 mt-4">Welcome Back !</h4>
                    <p class="text-muted">Sign in to continue to Medwise ERP</p>
                  </div>

                  <div class="p-2 mt-5">
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                      <div *ngIf="error" class="alert alert-danger mb-3 mt-0">{{error}}</div>
                      <div class="form-group auth-form-group mb-4">
                        <mat-icon matSuffix class="auth-page-icon">email</mat-icon>
                        <input type="email" formControlName="email" class="form-control auth-control" id="email"
                          placeholder="Email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                          <div *ngIf="f.email.errors.required">Email is required</div>
                          <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                        </div>
                      </div>

                      <div class="form-group auth-form-group mb-4">
                        <mat-icon matSuffix class="auth-page-icon">vpn_key</mat-icon>
                        <input type="password" formControlName="password" class="form-control auth-control"
                          id="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                          placeholder="Password" />
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                          <div *ngIf="f.password.errors.required">Password is required</div>
                        </div>
                      </div>

                      <div class="flex-sb-m w-full p-t-15 p-b-20">
                        <!-- <div class="form-check">
                          <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" value=""> Remember me
                            <span class="form-check-sign">
                              <span class="check"></span>
                            </span>
                          </label>
                        </div> -->
                        <!-- <div>
                          <a class="txt1" routerLink="/authentication/forgot-password">Forgot Password?</a>
                        </div> -->
                      </div>

                      <div class="container-login100-form-btn">
                        <button mat-flat-button color="primary" class="login100-form-btn" type="submit">
                          Login
                        </button>
                      </div>

                    </form>
                  </div>

                  <!-- <div class="mt-5 text-center">
                    <p>Don't have an account ? <a routerLink="/authentication/signup"
                        class="font-weight-medium text-primary">
                        Register </a> </p>
                    <ul class="list-unstyled social-icon mb-0 mt-3">
                      <li class="list-inline-item m-2"><a href="javascript:void(0)" class="rounded">
                          <i class="fab fa-google"></i>
                        </a></li>
                      <li class="list-inline-item m-2"><a href="javascript:void(0)" class="rounded flex-c-m">
                          <i class="fab fa-facebook-f"></i>
                        </a></li>
                      <li class="list-inline-item m-2"><a href="javascript:void(0)" class="rounded">
                          <i class="fab fa-twitter"></i>
                        </a></li>
                      <li class="list-inline-item m-2"><a href="javascript:void(0)" class="rounded">
                          <i class="fab fa-linkedin-in"></i>
                        </a></li>
                    </ul>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="auth-bg">
          <div class="bg-overlay"></div>
        </div>
      </div>
    </div>
  </div>
