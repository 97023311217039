import { Component, Injector } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { DiBaseFormComponent } from 'src/app/di-core/base/di.base-form.component';
import { ClientDTO } from 'src/app/med-erp/dto/common.client.dto';

@Component({
  selector: 'client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss']
})
export class ClientFormComponent extends DiBaseFormComponent<ClientDTO>{
  clientDTO: ClientDTO;
  clientForm: FormGroup;

  satusOptions: Array<any> = [
    {name: "Active", value: "ACTIVE"},
    {name: "Inactive", value: "INACTIVE"}
  ]

  constructor(protected injector: Injector) {
    super(injector);
  }


  initForm() {
    let component = this;
 
    component.clientForm.addControl('name', component.fb.control(component.clientDTO.name, [Validators.required, Validators.pattern('[a-zA-Z]+')]));
    component.clientForm.addControl('code', component.fb.control(component.clientDTO.code, [Validators.required,]));
    component.clientForm.addControl('status', component.fb.control(component.clientDTO.status, [Validators.required,]));
    component.clientForm.addControl('notes', component.fb.control(component.clientDTO.notes)); 

  }

}
