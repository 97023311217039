import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  AfterViewInit,
  Renderer2,
  ChangeDetectionStrategy
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DiUrlService } from "../../../di-core/service/url-service/url.service"; 

@Component({
  selector: 'app-post-login-layout',
  templateUrl: 'post-login-layout.component.html',
  styleUrls: ['./post-login-layout.component.sass']
})
export class PostLoginLayoutComponent  implements  AfterViewInit{

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private urlService: DiUrlService,
    private router: Router
  ) {
    let component = this;
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        console.log('prev:', event.url);
        component.urlService.setCurrentUrl(event.url);
      });
  }

  ngAfterViewInit() {
    this.renderer.addClass(this.document.body, 'theme-white');
  }
}
