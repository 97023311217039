import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './common/auth/login/login.component';
import { ClientCompanyFormComponent } from './common/client-company/client-company-form/client-company-form.component';
import { ClientCompanySearchComponent } from './common/client-company/client-company-search/client-company-search.component';
import { ClientFormComponent } from './common/client/client-form/client-form.component';
import { ClientSearchComponent } from './common/client/client-search/client-search.component';
import { CountryFormComponent } from './common/country/country-form/country-form.component';
import { CountrySearchComponent } from './common/country/country-search/country-search.component';
import { CurrencyFormComponent } from './common/currency/currency-form/currency-form.component';
import { CurrencySearchComponent } from './common/currency/currency-search/currency-search.component';
import { ListMasterFormComponent } from './common/list-master/list-master-form/list-master-form.component';
import { ListMasterSearchComponent } from './common/list-master/list-master-search/list-master-search.component';
import { UomFormComponent } from './common/uom/uom-form/uom-form.component';
import { UomSearchComponent } from './common/uom/uom-search/uom-search.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { CustomerOrderFormComponent } from './inventory/customer-order/customer-order-form/customer-order-form.component';
import { CustomerOrderSearchComponent } from './inventory/customer-order/customer-order-search/customer-order-search.component';
import { ItemFormComponent } from './inventory/item/item-form/item-form.component';
import { ItemSearchComponent } from './inventory/item/item-search/item-search.component';
import { PostLoginLayoutComponent } from './layout/post-login-layout/post-login-layout.component';
import { MedErpComponent } from './med-erp.component';

const routes: Routes = [
  {
    path: '-',
    component: PostLoginLayoutComponent,
    // canActivate: [AuthGuard],
    children: [
      { path: '', component: MedErpComponent },
      { path: 'country/form', component: CountryFormComponent },
      { path: 'country/form/:id', component: CountryFormComponent }
    ]
    
  },
  { path: 'dashboard', component: DashboardComponent },
  // { path: 'auth/login', component: LoginComponent },
  { path: 'country/search', component: CountrySearchComponent },
  { path: 'country/form', component: CountryFormComponent },
  { path: 'country/form/:id', component: CountryFormComponent },

  { path: 'list-master/:type/search', component: ListMasterSearchComponent },
  { path: 'list-master/:type/form', component: ListMasterFormComponent },
  { path: 'list-master/:type/form/:id', component: ListMasterFormComponent },

  { path: 'uom/search', component: UomSearchComponent },
  { path: 'uom/form', component: UomFormComponent },
  { path: 'uom/form/:id', component: UomFormComponent },

  { path: 'currency/search', component: CurrencySearchComponent },
  { path: 'currency/form', component: CurrencyFormComponent },
  { path: 'currency/form/:id', component: CurrencyFormComponent },

  { path: 'client/search', component: ClientSearchComponent },
  { path: 'client/form', component: ClientFormComponent },
  { path: 'client/form/:id', component: ClientFormComponent },

  { path: 'client-company/search', component: ClientCompanySearchComponent },
  { path: 'client-company/form', component: ClientCompanyFormComponent },
  { path: 'client-company/form/:id', component: ClientCompanyFormComponent },

  { path: 'item/search', component: ItemSearchComponent },
  { path: 'item/form', component: ItemFormComponent },
  { path: 'item/form/:id', component: ItemFormComponent },

  { path: 'customer-order/search', component: CustomerOrderSearchComponent },
  { path: 'customer-order/form', component: CustomerOrderFormComponent },
  { path: 'customer-order/form/:id', component: CustomerOrderFormComponent }

  

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MedErpRoutingModule { }
