import { TemplateRef } from "@angular/core";

export class DiSearchColumn{
    name: string;
    prop: string;
    sortable: boolean;
    resizeable: boolean; 
    cellTemplate: TemplateRef<any>;
    serialNo: boolean; 
    headerClass: string;
    cellClass: string; 
    constructor(options: any) {
        this.name = options["name"] || "";
        this.prop = options["prop"] || "";
        this.sortable = options["sortable"] || false;
        this.resizeable = options["resizeable"] || false;
        this.cellTemplate = options["cellTemplate"];
        this.serialNo = options["serialNo"] || false;
        this.headerClass =  this.cellClass = options["class"];
    }
}