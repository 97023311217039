<di-form [formGroup]="countryForm" title="Country">
  <div class="row">
    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
      <di-textbox placeholder="Country Name" fcName="name"></di-textbox>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
      <di-textbox placeholder="Country Code" fcName="code"></di-textbox>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
      <di-select placeholder="Status" fcName="status" [options]="satusOptions" value="value" dv="name"></di-select>
    </div>
  </div>
  <div class="row">
    <di-textarea placeholder="Remarks" fcName="remark"></di-textarea>
  </div>
</di-form>