import { Component, OnInit, Injector } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DiStringUtil } from "../util/di.string.util";

@Component({
  template: '',
})
export abstract class DiBaseComponent implements OnInit {
  protected route: ActivatedRoute;
  protected router: Router;
  protected modulelName: string;

  protected baseModuleURL = null;
  protected dialogRef: MatDialogRef<any, any>;
  protected isDialog: boolean = false;

  constructor(protected injector: Injector, protected options?: Object) {
    let component = this;
    component.route = injector.get(ActivatedRoute);
    component.router = injector.get(Router);
    component.modulelName = component.getModuleName();
    component.modulelName = (!!options && options["modulelName"]) || component.modulelName;

  }

  ngOnInit(): void {
  }

  private getModuleName(): string {
    let component = this;
    let currentURL = component.router.url;
    let tokens: string[] = currentURL.split("/");
    let moduleName = ""

    if (DiStringUtil.isContains(currentURL, '/search')) {
      moduleName = tokens[tokens.length - 2];
      component.baseModuleURL = DiStringUtil.subtract(component.router.url, '/' + tokens[tokens.length - 1]);
    }
    else if (DiStringUtil.isContains(currentURL, '/form/')) {
      moduleName = tokens[tokens.length - 3];
      component.baseModuleURL = DiStringUtil.subtract(component.router.url, '/' + tokens[tokens.length - 2] + '/' + tokens[tokens.length - 1]);
    }
    else if (DiStringUtil.isContains(currentURL, '/form')) {
      moduleName = tokens[tokens.length - 2];
      component.baseModuleURL = DiStringUtil.subtract(component.router.url, '/' + tokens[tokens.length - 1]);
    }
    let subTokens: string[] = moduleName.split("-");

    moduleName = subTokens[0];
    for (let i = 1; i < subTokens.length; i++) {
      moduleName += DiStringUtil.toTitle(subTokens[i]);
    }
    return moduleName;
  }

  public setDialogRef(dialogRef: MatDialogRef<any, any>){
    let component = this;
    component.dialogRef = dialogRef;
    component.isDialog =  true;
  }
}