import { Component, Injector } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { DiBaseFormComponent } from 'src/app/di-core/base/di.base-form.component';
import { CurrencyDTO } from 'src/app/med-erp/dto/common.currency.dto';

@Component({
  selector: 'currency-form',
  templateUrl: './currency-form.component.html',
  styleUrls: ['./currency-form.component.scss']
})
export class CurrencyFormComponent extends DiBaseFormComponent<CurrencyDTO>{
  currencyDTO: CurrencyDTO;
  currencyForm: FormGroup;

  satusOptions: Array<any> = [
    {name: "Active", value: "ACTIVE"},
    {name: "Inactive", value: "INACTIVE"}
  ]

  constructor(protected injector: Injector) {
    super(injector);
  }


  initForm() {
    let component = this;
 
    component.currencyForm.addControl('name', component.fb.control(component.currencyDTO.name, [Validators.required, Validators.pattern('[a-zA-Z]+')]));
    component.currencyForm.addControl('code', component.fb.control(component.currencyDTO.code, [Validators.required,]));
    component.currencyForm.addControl('symbole', component.fb.control(component.currencyDTO.symbole, [Validators.required,]));
    component.currencyForm.addControl('status', component.fb.control(component.currencyDTO.status, [Validators.required,]));
    component.currencyForm.addControl('notes', component.fb.control(component.currencyDTO.notes)); 

  }

}
