import { Component, Injector } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { DiBaseFormComponent } from 'src/app/di-core/base/di.base-form.component';
import { ClientCompanyDTO } from 'src/app/med-erp/dto/common.client-company.dto';

@Component({
  selector: 'client-company-form',
  templateUrl: './client-company-form.component.html',
  styleUrls: ['./client-company-form.component.scss']
})
export class ClientCompanyFormComponent extends DiBaseFormComponent<ClientCompanyDTO>{
  clientCompanyDTO: ClientCompanyDTO;
  clientCompanyForm: FormGroup;

  satusOptions: Array<any> = [
    {name: "Active", value: "ACTIVE"},
    {name: "Inactive", value: "INACTIVE"}
  ]

  constructor(protected injector: Injector) {
    super(injector);
  }


  initForm() {
    let component = this;
 
    component.clientCompanyForm.addControl('name', component.fb.control(component.clientCompanyDTO.name, [Validators.required, Validators.pattern('[a-zA-Z]+')]));
    component.clientCompanyForm.addControl('code', component.fb.control(component.clientCompanyDTO.code, [Validators.required,]));
    component.clientCompanyForm.addControl('status', component.fb.control(component.clientCompanyDTO.status, [Validators.required,]));
    component.clientCompanyForm.addControl('clientId', component.fb.control(component.clientCompanyDTO.clientId));
    component.clientCompanyForm.addControl('addressLine1', component.fb.control(component.clientCompanyDTO.addressLine1));
    component.clientCompanyForm.addControl('addressLine2', component.fb.control(component.clientCompanyDTO.addressLine2));
    component.clientCompanyForm.addControl('addressCityId', component.fb.control(component.clientCompanyDTO.addressCityId));
    component.clientCompanyForm.addControl('addressStateId', component.fb.control(component.clientCompanyDTO.addressStateId));
    component.clientCompanyForm.addControl('addressCountryId', component.fb.control(component.clientCompanyDTO.addressCountryId));
    component.clientCompanyForm.addControl('addressZipcode', component.fb.control(component.clientCompanyDTO.addressZipcode));
    component.clientCompanyForm.addControl('contactPerson', component.fb.control(component.clientCompanyDTO.contactPerson));
    component.clientCompanyForm.addControl('email', component.fb.control(component.clientCompanyDTO.email));
    component.clientCompanyForm.addControl('phoneNumber', component.fb.control(component.clientCompanyDTO.phoneNumber));
    component.clientCompanyForm.addControl('taxId', component.fb.control(component.clientCompanyDTO.taxId));
    component.clientCompanyForm.addControl('notes', component.fb.control(component.clientCompanyDTO.notes)); 

  }

}
