import { Component, Injector } from '@angular/core';
import { DiBaseSearchComponent } from 'src/app/di-core/base/di.base-search.component';
import { DiSearchColumn } from 'src/app/di-core/components/di-search/di-search.column';
import { CustomerOrderSearchDTO } from "../../../search-dto/inventory.customer-order-search.dto";
import { CustomerOrderDTO } from "../../../dto/inventory.customer-ordert.dto";

@Component({
  selector: 'customer-order-search',
  templateUrl: './customer-order-search.component.html',
  styleUrls: ['./customer-order-search.component.scss']
})
export class CustomerOrderSearchComponent extends DiBaseSearchComponent<CustomerOrderSearchDTO, CustomerOrderDTO>{
  
  customerOrderSearchDTO: CustomerOrderSearchDTO;


  constructor(protected injector: Injector) {
    super(injector);
  }

  diInit(){
    let component = this;
    component.columns = [
      new DiSearchColumn({name: "#", serialNo: true}),
      new DiSearchColumn({name: "Order No.", prop: "no", width: 150}),
      new DiSearchColumn({name: "Order Date", prop: "date", width: 150}),
      new DiSearchColumn({name: "Company", prop: "companyName"}),
      new DiSearchColumn({name: "Target Date", prop: "companyName"}),
      new DiSearchColumn({name: "Total", prop: "total", pipe: {transform: (value) => {return "123"}}}),
      
    ];
  }

}
