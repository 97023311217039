import { Component } from '@angular/core';

@Component({
  selector: 'app-med-erp',
  templateUrl: './med-erp.component.html',
  styleUrls: ['./med-erp.component.sass']
})
export class MedErpComponent {

}
