import { Component, Injector } from '@angular/core';

import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { DiResponseDTO } from '../../../../di-core/misc/di.response.dto';
import { DiUrlService } from '../../../../di-core/service/url-service/url.service';
import { DummyDataService } from 'src/app/med-erp/service/dummy-data-service/dummy-data.service';
import { CountryDTO } from "../../../dto/common.country.dto";
import { DiBaseFormComponent } from "../../../../di-core/base/di.base-form.component";
@Component({
  selector: 'app-country-form',
  templateUrl: './country-form.component.html',
  styleUrls: ['./country-form.component.scss']
})
export class CountryFormComponent extends DiBaseFormComponent<CountryDTO>{
  countryDTO: CountryDTO;
  countryForm: FormGroup;

  satusOptions: Array<any> = [
    {name: "Active", value: "ACTIVE"},
    {name: "Inactive", value: "INACTIVE"}
  ]

  constructor(protected injector: Injector) {
    super(injector);
  }


  initForm() {
    let component = this;
 
    component.countryForm.addControl('name', component.fb.control(component.countryDTO.name, [Validators.required, Validators.pattern('[a-zA-Z]+')]));
    component.countryForm.addControl('code', component.fb.control(component.countryDTO.code, [Validators.required,]));
    component.countryForm.addControl('status', component.fb.control(component.countryDTO.status, [Validators.required,]));
    component.countryForm.addControl('remark', component.fb.control(component.countryDTO.remark)); 

  }
}
