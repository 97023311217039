import { Component, EventEmitter, Host, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { startWith, map, Observable } from 'rxjs';
import { DiFormService } from 'src/app/di-core/service/di-form-service/di-form.service';
import { DiFormComponent } from '../../di-form/di-form.component';

@Component({
  selector: 'di-autocomplete',
  templateUrl: './di-autocomplete.component.html',
  styleUrls: ['./di-autocomplete.component.scss']
})
export class DiAutocompleteComponent implements OnInit {
  @Input() placeholder: string;
  @Input() fcName: string;
  @Input() hintMessage: string;
  @Input() addNew: boolean = false;

  @Input() options$: Observable<Array<any>>;
  @Input() dv: string | Function;
  
  @Output() onAddNew = new EventEmitter();
  @Output() onLookup = new EventEmitter();
  @Output() onSelect = new EventEmitter();


  diForm: DiFormComponent
  formGroup: FormGroup;
  errorMessage: string;

  filteredOptions: Array<any> = [];

  constructor(@Host() diForm: DiFormComponent, private formService: DiFormService) {
    let component = this;
    component.diForm = diForm;
  }
  ngOnInit(): void {
    let component = this;
    component.formService.initFormGroup$.subscribe(initFormGroup => {
      if (initFormGroup == component.diForm.formGroup) {
        component.formGroup = component.diForm.formGroup;
        component.options$.subscribe(options=>{
          console.log("options", options);
          component.filteredOptions = options;
        });
        // component.filterOptions();
        // console.log("" 
        // component.formGroup[component.fcName].valueChanges.subscribe(()=>{
        //   component.filterOptions();
        // })
        // component.filteredOptions = component.formGroup[component.fcName].valueChanges.pipe(
        //   startWith(''),
        //   map((value) => (typeof value === 'string' ? value : value.name)),
        //   map((name) => (name ? this._filter(name) : this.options.slice()))
        // );
      }
    })

   
  }

  filterOptions(event?){
    let component = this;
    // component.onTest.emit(component.options)
    console.log("event", event);
    component.onLookup.emit(event.target.value);
    // console.log("component.options", component.options);
    // console.log("getOptions called", typeof component.options)
    // if(component.options$ instanceof Array){
    //   component.filteredOptions = component.options;
    //   // return new Observable(observable=>{
    //   //   observable.next(component.options);
    //   // });
    // }
    // else if(component.options instanceof Function){
    //   console.log("component.options()", component.options());
    //   component.options.subscribe(options=>{
    //     console.log("options", options);
    //     component.filteredOptions = options;
    //   });
    //   // return component.options;
    // }
  }
  getOptionDv(option){
    let component = this;
    console.log("A getOptionDv",(typeof component.dv));
    let optionDv = "";
    if(typeof component.dv == "string"){
      optionDv =  option[component.dv];
    }
    else if(typeof component.dv == "function"){
      optionDv = component.dv(option);
    }
    else{
      optionDv = option;
    }
    if(typeof option == "object"){
      option.__diValue =  optionDv;
    }
    
    console.log("1 getOptionDv option",(typeof option));
    return optionDv;
  }

  displayFn(option: any): string {
    let component = this;
    
    if(!option){
      return "";
    }
    else if(typeof option == "object"){
      return option.__diValue;
    }
    else{
      return option;
    }
    // return !!component.getOptionDv ? component.getOptionDv(option) : "";
  }

  onOptionSelect(event){
    
    let component = this;
    component.onSelect.emit(event.option.value);
  }

  fnAddNew(event){
    let component = this;
    event.stopPropagation();
    component.onAddNew.emit(event)
  }
}
