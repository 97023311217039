<div class="card" *ngIf="!isSubForm">
    <div class="header">
        <h2>{{title}}</h2>
    </div>
    <div class="body">
        <form class="register-form m-4" *ngIf="!!formGroup" [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
            <!-- <ng-content></ng-content> -->
            <ng-container *ngTemplateOutlet="content"></ng-container>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-2">
                    <button type="button" mat-raised-button (click)="onCancelForm($event)"><i class="fas fa-ban msr-2"></i> Cancel</button>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-2 text-end">
                    <button mat-raised-button color="primary"><i class="fas fa-check msr-2"></i>Save</button>
                </div>
            </div>
        </form>
    </div>
</div>
<ng-container *ngIf="!!isSubForm">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>
<ng-template #content>
    <ng-content></ng-content>
</ng-template>