import { Component, Injector, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { DiBaseFormComponent } from 'src/app/di-core';
import { DiResponseDTO } from 'src/app/di-core/misc/di.response.dto';
import { CustomerOrderDetailDTO } from 'src/app/med-erp';
import { ClientCompanyDTO } from 'src/app/med-erp/dto/common.client-company.dto';
import { ClientDTO } from 'src/app/med-erp/dto/common.client.dto';
import { CountryDTO } from 'src/app/med-erp/dto/common.country.dto';
import { CustomerOrderDTO } from 'src/app/med-erp/dto/inventory.customer-ordert.dto';

@Component({
  selector: 'customer-order-form',
  templateUrl: './customer-order-form.component.html',
  styleUrls: ['./customer-order-form.component.scss']
})
export class CustomerOrderFormComponent extends DiBaseFormComponent<CustomerOrderDTO>{
  customerOrderDTO: CustomerOrderDTO;
  customerOrderForm: FormGroup;

  private toggleDetail: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public toggleDetail$: Observable<number> = this.toggleDetail.asObservable();

  @ViewChild('tmplCustomerOrderDetail', { read: TemplateRef, static: true })
  tmplCustomerOrderDetail: TemplateRef<any>;

  @ViewChild('vcCustomerOrderDetails', { read: ViewContainerRef, static: true })
  vcCustomerOrderDetails: ViewContainerRef;

  orderTypeOptions: Array<any> = [
    { name: "Commercial", value: "Commercial" },
    { name: "Development", value: "Development" }
  ]

  companyOptions: Array<any> = [
    { name: "Medwise Overseas - India", value: "Medwise Overseas - India" },
    { name: "Medwise Overseas - UAE", value: "Medwise Overseas - UAE" }
  ]

  orderDetailTypes: Array<any> = [
    { name: "New", value: "new" },
    { name: "Repeat", value: "repeat" }
  ]
  clientCompanyDTOs: Array<ClientCompanyDTO> = [];

  private countries: BehaviorSubject<Array<CountryDTO>> = new BehaviorSubject<Array<CountryDTO>>(null);
  public countries$: Observable<Array<CountryDTO>> = this.countries.asObservable();

  private clients: BehaviorSubject<Array<ClientDTO>> = new BehaviorSubject<Array<ClientDTO>>(null);
  public clients$: Observable<Array<ClientDTO>> = this.clients.asObservable();

  constructor(protected injector: Injector) {
    super(injector);
  }


  initForm() {
    let component = this;
 
    component.customerOrderForm.addControl('type', component.fb.control(component.customerOrderDTO.type, [Validators.required,]));
    component.customerOrderForm.addControl('companyId', component.fb.control(component.customerOrderDTO.companyId, [Validators.required,]));
    component.customerOrderForm.addControl('no', component.fb.control(component.customerOrderDTO.no, [Validators.required, Validators.pattern('[a-zA-Z]+')]));
    component.customerOrderForm.addControl('countryId', component.fb.control(component.customerOrderDTO.countryId, [Validators.required,]));
    component.customerOrderForm.addControl('clientId', component.fb.control(component.customerOrderDTO.clientId, [Validators.required,]));
    component.customerOrderForm.addControl('date', component.fb.control(component.customerOrderDTO.date, [Validators.required,]));
    component.customerOrderForm.addControl('clientCompanyId', component.fb.control(component.customerOrderDTO.clientCompanyId, [Validators.required,]));
    component.customerOrderForm.addControl('timeline', component.fb.control(component.customerOrderDTO.timeline, [Validators.required,]));
    component.customerOrderForm.addControl('temperatureCargo', component.fb.control(component.customerOrderDTO.temperatureCargo, [Validators.required,]));
    component.customerOrderForm.addControl('loadingPort', component.fb.control(component.customerOrderDTO.loadingPort, [Validators.required,]));
    component.customerOrderForm.addControl('dischargePort', component.fb.control(component.customerOrderDTO.dischargePort, [Validators.required,]));
    component.customerOrderForm.addControl('requiredDocumentNotes', component.fb.control(component.customerOrderDTO.remarks)); 
    component.customerOrderForm.addControl('remarks', component.fb.control(component.customerOrderDTO.remarks)); 
    
    component.fetchClientCompany();
    if(!component.customerOrderDTO.customerOrderDetailDTOs){
      component.customerOrderDTO.customerOrderDetailDTOs = [];
    }
    let customerOrderDetailDTO: CustomerOrderDetailDTO = {} as CustomerOrderDetailDTO;
    
    component.addDetail(customerOrderDetailDTO);
    // component.addDetail(customerOrderDetailDTO);
    // component.addDetail(customerOrderDetailDTO);
    // component.addDetail(customerOrderDetailDTO);
    // component.addDetail(customerOrderDetailDTO);
    // component.addDetail(customerOrderDetailDTO);

  }
  addNewDetail(){
    let component = this;
    let customerOrderDetailDTO: CustomerOrderDetailDTO = {} as CustomerOrderDetailDTO;    
    component.addDetail(customerOrderDetailDTO);
  }

  private addDetail(customerOrderDetailDTO: CustomerOrderDetailDTO){
    let component = this;
    component.customerOrderDTO.customerOrderDetailDTOs.push(customerOrderDetailDTO);    
    component.vcCustomerOrderDetails.createEmbeddedView(component.tmplCustomerOrderDetail, {
        $implicit: component.customerOrderForm,
        customerOrderDetailDTO: customerOrderDetailDTO,
        toggleDetail$: component.toggleDetail$,
        index: component.customerOrderDTO.customerOrderDetailDTOs.length-1,
        orderDetailTypes: component.orderDetailTypes
      }
    );
    component.toggleDetail.next(component.customerOrderDTO.customerOrderDetailDTOs.length-1);
  } 

  

  fetchCountries(searchString?: string) {
    let component = this;
    component.dummyDataService.getAllList("country").subscribe((responseDTO: DiResponseDTO) => {
      let countryData = responseDTO.body;
      if (!!searchString) {
        countryData = countryData.filter(
          (option) => option.name.toLowerCase().indexOf(searchString) === 0
        );
      }
      component.countries.next(countryData);
    })
  }

  fetchClients(searchString?: string) {
    let component = this;
    component.dummyDataService.getAllList("client").subscribe((responseDTO: DiResponseDTO) => {
      let clientData = responseDTO.body;
      if (!!searchString) {
        clientData = clientData.filter(
          (option) => option.name.toLowerCase().indexOf(searchString) === 0
        );
      }
      component.clients.next(clientData);
    })
  }
  fetchClientCompany() {
    let component = this;
    component.dummyDataService.getAllList("clientCompany").subscribe((responseDTO: DiResponseDTO) => {
      component.clientCompanyDTOs = responseDTO.body;
    })
  }


  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

}
