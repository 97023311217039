import { Component, Injector, TemplateRef, ViewChild, ContentChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { CountrySearchDTO } from "../../../search-dto/common.country-search.dto";
import { CountryDTO } from "../../../dto/common.country.dto";
import { DiBaseSearchComponent } from '../../../../di-core/base/di.base-search.component';
import { DiSearchColumn } from "../../../../di-core/components/di-search/di-search.column";

@Component({
  selector: 'app-country-search',
  templateUrl: './country-search.component.html',
  styleUrls: ['./country-search.component.scss']
})
export class CountrySearchComponent extends DiBaseSearchComponent<CountrySearchDTO, CountryDTO>{
  
  @ViewChild('tmplRemark', { read: TemplateRef, static: true })
  tmplRemark: TemplateRef<any> = null;

  countrySearchDTO: CountrySearchDTO;

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  constructor(protected injector: Injector) {
    super(injector);
  }

  diInit(){
    let component = this;
    component.columns = [
      new DiSearchColumn({name: "#", serialNo: true}),
      new DiSearchColumn({name: "Country Name", prop: "name", width: 150}),
      new DiSearchColumn({name: "Country Code", prop: "code", width: 150}),
      new DiSearchColumn({name: "Remarks", cellTemplate: this.tmplRemark})
    ];
  }

 

  filterDatatable(event) {
    // get the value of the key pressed and make it lowercase
    const val = event.target.value.toLowerCase();
    // get the amount of columns in the table
    // const colsAmt = this.columns.length;
    // get the key names of each column in the dataset
    // const keys = Object.keys(this.filteredData[0]);
    // assign filtered matches to the active datatable
    // this.data = this.filteredData.filter(function (ite/m) {
      // iterate through each row's column data
      // for (let i = 0; i < colsAmt; i++) {
      //   // check for a match
      //   if (
      //     item[keys[i]].toString().toLowerCase().indexOf(val) !== -1 ||
      //     !val
      //   ) {
      //     // found match, return true to add to result set
      //     return true;
      //   }
      // }
    // });
    // whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

}
