import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DiFileUploadUtil } from "../../../util/di.file-upload.util";

@Component({
  selector: 'di-file-upload',
  templateUrl: './di-file-upload.component.html',
  styleUrls: ['./di-file-upload.component.scss']
})
export class DiFileUploadComponent {
  @Input() placeholder: string;
  @Input() fcName: string;
  @Input() hintMessage: string;

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  fileNames:Array<string> = []


  public onClickButton(event) {
    let component = this;
    let clickEvent = new MouseEvent('click', { bubbles: true });
    component.fileInput.nativeElement.dispatchEvent(clickEvent);
  }


  public onFileSelect(event) {
    let component = this;
    // component.isModified = true;
    let files: FileList = event.target.files;
    if (files.length > 0) {
      if (files[0] != null && DiFileUploadUtil.isValidImageFile(files[0].name, DiFileUploadUtil.VALID_IMAGE_EXTENSION)) {
        component.fileNames = [];
        for (var i = 0; i < files.length; i++) {
          component.fileNames.push(files[i].name);
        }
        // LSEvent.fire(LSLoaderComponent.SHOW_LOADER, "NO_DATA");
        // component.uploadService.uploadFile(files, function (response) {
        //   if (response.body != null && response.httpStatus == 200) {
        //     component.itemImageDTO = {
        //       itemId: component.itemImagesDTO.itemId,
        //       defaultImage: false,
        //       sequenceNo: ((component.itemImagesDTO.itemImageDTOs.length + 1) * 10),
        //       imageId: response.body.id,
        //       imageUrl: response.body.url
        //     } as ItemImageDTO;
        //     component.itemImagesDTO.itemImageDTOs.push(component.itemImageDTO);
        //     if (component.itemImagesDTO.itemImageDTOs.length == 1) {
        //       component.changeDefaultImage(0);
        //     }
        //   } else if (response.httpStatus == 400) {
        //     component.lsDialogService.info(response.body[0].defaultMessage);
        //   }
        //   LSEvent.fire(LSLoaderComponent.REMOVE_LOADER, "NO_DATA");
        //   event.target.value = '';
        // });
      } else {
        // component.lsDialogService.info(FileUploadUtil.INVALID_IMAGE_ERROR_MSG);
        console.error(DiFileUploadUtil.INVALID_IMAGE_ERROR_MSG);
      }
    }
  }
}
