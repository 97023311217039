<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="header">
            <h2>Input mask </h2>
          </div>
          <div class="body">
            <div class="row">
              <div class="col-4">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Date Format</mat-label>
                  <span matPrefix>
                    <mat-icon class="msr-2">date_range</mat-icon>
                  </span>
                  <input matInput placeholder="EX: 10/07/2020" mask="00/00/0000" />
                </mat-form-field>
              </div>
              <div class="col-4">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Date and hour</mat-label>
                  <span matPrefix>
                    <mat-icon class="msr-2">date_range</mat-icon>
                  </span>
                  <input matInput placeholder="EX: 10/07/20 11:05:29 " mask="00/00/00 00:00:00" />
                </mat-form-field>
              </div>
              <div class="col-4">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Valid 24 hour format</mat-label>
                  <span matPrefix>
                    <mat-icon class="msr-2">access_time</mat-icon>
                  </span>
                  <input matInput placeholder="EX: 23:05:29 " mask="Hh:m0:s0" />
                </mat-form-field>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Prefix</mat-label>
                  <span matPrefix>
                    <mat-icon class="msr-2">phone</mat-icon>
                  </span>
                  <input matInput placeholder="EX: (435) 345 34 54" prefix="+91 " mask="(000) 000 00 00" />
                </mat-form-field>
              </div>
              <div class="col-4">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Suffix</mat-label>
                  <span matPrefix>
                    <mat-icon class="msr-2">attach_money</mat-icon>
                  </span>
                  <input matInput placeholder="EX: 6762 " suffix=" $" mask="0000" />
                </mat-form-field>
              </div>
              <div class="col-4">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Drop Special Character</mat-label>
                  <span matPrefix>
                    <mat-icon class="msr-2">error_outline</mat-icon>
                  </span>
                  <input matInput placeholder="EX: 565-656.55" [dropSpecialCharacters]="false" mask="000-000.00" />
                </mat-form-field>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Show Mask Type</mat-label>
                  <span matPrefix>
                    <mat-icon class="msr-2">phone</mat-icon>
                  </span>
                  <input matInput placeholder="EX: (435) 345 34 54" mask="(000) 000-0000" [showMaskTyped]="true" />
                </mat-form-field>
              </div>
              <div class="col-4">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Thousand separator</mat-label>
                  <span matPrefix>
                    <mat-icon class="msr-2">attach_money</mat-icon>
                  </span>
                  <input matInput placeholder="EX: 125 897 854 " mask="separator" />
                </mat-form-field>
              </div>
              <div class="col-4">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Dot separator</mat-label>
                  <span matPrefix>
                    <mat-icon class="msr-2">create</mat-icon>
                  </span>
                  <input matInput placeholder="EX: 125.878.754" mask="separator" thousandSeparator="." />
                </mat-form-field>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Secure input</mat-label>
                  <span matPrefix>
                    <mat-icon class="msr-2">credit_card</mat-icon>
                  </span>
                  <input matInput placeholder="EX: ***/*4/4354" [hiddenInput]="true" mask="XXX/X0/0000" />
                </mat-form-field>
              </div>
              <div class="col-4">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>IP Address</mat-label>
                  <span matPrefix>
                    <mat-icon class="msr-2">desktop_mac</mat-icon>
                  </span>
                  <input matInput placeholder="EX: 443.543.534.535" mask="IP" [showMaskTyped]="true" />
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="header">
            <h2>Autocomplete</h2>
          </div>
          <div class="body">
            <div class="row">
              <div class="col-6">
                <h6>Display value autocomplete</h6>
                <form class="example-form">
                  <mat-form-field class="example-full-width">
                    <mat-label>Assignee</mat-label>
                    <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{option.name}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="header">
            <h2>Hint Label</h2>
          </div>
          <div class="body">
            <div class="row">
              <div class="col-6">
                <h6>Form field with hints</h6>
                <mat-form-field hintLabel="Max 10 characters" class="example-full-width" appearance="fill">
                  <mat-label>Enter some input</mat-label>
                  <input matInput #input maxlength="10" placeholder="Ex. Nougat">
                  <mat-hint align="end">{{input.value?.length || 0}}/10</mat-hint>
                </mat-form-field>
              </div>
              <div class="col-6">
                <h6>Form field with hints</h6>
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Select me</mat-label>
                  <mat-select>
                    <mat-option value="option1">Option 1</mat-option>
                    <mat-option value="option2">Option 2</mat-option>
                    <mat-option value="option3">Option 3</mat-option>
                  </mat-select>
                  <mat-hint align="end">Here's the dropdown arrow ^</mat-hint>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="header">
            <h2>Error Message</h2>
          </div>
          <div class="body">
            <div class="row">
              <div class="col-6">
                <h6>Form field with error messages</h6>
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Enter your email</mat-label>
                  <input matInput placeholder="pat@example.com" [formControl]="email" required>
                  <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="header">
            <h2>Prefix & suffix</h2>
          </div>
          <div class="body">
            <div class="row">
              <div class="col-6">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Enter your password</mat-label>
                  <input matInput [type]="hide ? 'password' : 'text'">
                  <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Amount</mat-label>
                  <input matInput type="number" class="example-right-align">
                  <span matPrefix>$&nbsp;</span>
                  <span matSuffix>.00</span>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
