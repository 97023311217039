import { Component, Injector } from '@angular/core';
import { DiBaseSearchComponent } from 'src/app/di-core/base/di.base-search.component';
import { DiSearchColumn } from 'src/app/di-core/components/di-search/di-search.column';
import { UomSearchDTO } from "../../../search-dto/common.uom-search.dto";
import { UomDTO } from "../../../dto/common.uom.dto";

@Component({
  selector: 'uom-search',
  templateUrl: './uom-search.component.html',
  styleUrls: ['./uom-search.component.scss']
})
export class UomSearchComponent extends DiBaseSearchComponent<UomSearchDTO, UomDTO>{
  
  uomSearchDTO: UomSearchDTO;


  constructor(protected injector: Injector) {
    super(injector);
  }

  diInit(){
    let component = this;
    component.columns = [
      new DiSearchColumn({name: "#", serialNo: true}),
      new DiSearchColumn({name: "Uom Name", prop: "name", width: 150}),
      new DiSearchColumn({name: "Display Value", prop: "dv", width: 150}),
      new DiSearchColumn({name: "Type", prop: "typeDv", width: 150}),
      new DiSearchColumn({name: "Notes", prop: "notes"}),
    ];
  }

}
