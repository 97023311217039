import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/service/auth.service';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/UnsubscribeOnDestroyAdapter';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent  extends UnsubscribeOnDestroyAdapter
implements OnInit
{
loginForm: UntypedFormGroup;
submitted = false;
error = '';
hide = true;
constructor(
  private formBuilder: UntypedFormBuilder,
  private router: Router,
  private authService: AuthService
) {
  super();
}
ngOnInit() {
  this.loginForm = this.formBuilder.group({
    email: [
      'admin@atrio.com',
      [Validators.required, Validators.email, Validators.minLength(5)]
    ],
    password: ['admin', Validators.required]
  });
}
get f() {
  return this.loginForm.controls;
}
onSubmit() {
  this.submitted = true;
  this.error = '';
  if (this.loginForm.invalid) {
    this.error = 'Username and Password not valid !';
    return;
  } else {
    this.subs.sink = this.authService
      .login(this.f.email.value, this.f.password.value)
      .subscribe(
        (res) => {
          if (res) {
            const token = this.authService.currentUserValue.token;
            if (token) {
              this.router.navigate(['/med-erp/dashboard']);
            }
          } else {
            this.error = 'Invalid Login';
          }
        },
        (error) => {
          this.error = error;
          this.submitted = false;
        }
      );
  }
}
}
