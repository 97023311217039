import { Component, Injector } from '@angular/core';
import { DiBaseSearchComponent } from 'src/app/di-core/base/di.base-search.component';
import { DiSearchColumn } from 'src/app/di-core/components/di-search/di-search.column';
import { CurrencySearchDTO } from "../../../search-dto/common.currency-search.dto";
import { CurrencyDTO } from "../../../dto/common.currency.dto";

@Component({
  selector: 'currency-search',
  templateUrl: './currency-search.component.html',
  styleUrls: ['./currency-search.component.scss']
})
export class CurrencySearchComponent extends DiBaseSearchComponent<CurrencySearchDTO, CurrencyDTO>{
  
  currencySearchDTO: CurrencySearchDTO;


  constructor(protected injector: Injector) {
    super(injector);
  }

  diInit(){
    let component = this;
    component.columns = [
      new DiSearchColumn({name: "#", serialNo: true}),
      new DiSearchColumn({name: "Currency Name", prop: "name", width: 150}),
      new DiSearchColumn({name: "Currency code", prop: "code", width: 150}),
      new DiSearchColumn({name: "Symbole", prop: "symbole", width: 150}),
      new DiSearchColumn({name: "Notes", prop: "notes"}),
    ];
  }

}
