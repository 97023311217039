import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DiFormService {
  private initFormGroup: BehaviorSubject<FormGroup> = new BehaviorSubject<FormGroup>(null);
  public initFormGroup$: Observable<FormGroup> = this.initFormGroup.asObservable();

  private onSubmit: BehaviorSubject<FormGroup> = new BehaviorSubject<FormGroup>(null);
  public onSubmit$: Observable<FormGroup> = this.onSubmit.asObservable();

  private onCancel: BehaviorSubject<FormGroup> = new BehaviorSubject<FormGroup>(null);
  public onCancel$: Observable<FormGroup> = this.onCancel.asObservable();

  

  constructor() { }

  setInitFormGroup(initFormGroup: FormGroup) {
    let service = this;
    service.initFormGroup.next(initFormGroup);
  }

  submit(initFormGroup: FormGroup) {
    let service = this;
    service.onSubmit.next(initFormGroup);
  }

  cancel(initFormGroup: FormGroup) {
    let service = this;
    service.onCancel.next(initFormGroup);
  }

  
}
