<di-search
           title="Country"
           searchPlaceholder="Search"
           [columns]="columns"
           [pageDTO]="pageDTO">

</di-search>
<ng-template #tmplRemark let-value="value" let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
  <span>
    {{row.name}}-{{row.code}}
  </span>
</ng-template>